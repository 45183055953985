import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import {store} from "@/store/store";
import net from '@/net/Net.js'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vant);
Vue.prototype.$store = store
Vue.prototype.$net = net

// 申明忽略标签 - 用于跳转小程序
Vue.config.ignoredElements = [
  'wx-open-launch-weapp'
]

router.beforeEach((to,from,next) =>{
  if(to.meta.title){
    document.title = "新冠居家健康管理"//to.meta.title
  }
  next();
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
