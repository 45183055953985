<template>
  <div class="mental-health-test">

    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <div class="question-title">{{questionType === 0? "7项广泛性焦虑障碍量表（GAD-7）":"9项抑郁症筛查量表（PHQ-9)"}}</div>
      <div class="question-hint">
        <p>根据过去两周的状况，请您回答是否存在下列描述的状况及频率，请看清楚问题后选择相应的数字。</p>
        <p>本量表采用0-3分的四级评分法评分。</p>
        <ul>
          <li>0（完全不会）</li>
          <li>1（有几天，不超过一半的日子）</li>
          <li>2（一半以上的日子）</li>
          <li>3（几乎每天）</li>
        </ul>
      </div>
      <div class="gad-7-questions" v-if="questionType === 0">
        <div class="question-item" v-for="(question,index) in gad7Questions" :key="question">
          <p>{{question}}</p>
          <el-radio-group v-model="gad7Results[index]">
            <el-radio :label="0">0</el-radio>
            <el-radio :label="1">1</el-radio>
            <el-radio :label="2">2</el-radio>
            <el-radio :label="3">3</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="phq-9-questions" v-else>
        <div class="question-item" v-for="(question,index) in phq9Questions" :key="question">
          <p>{{question}}</p>
          <el-radio-group v-model="phq9Results[index]">
            <el-radio :label="0">0</el-radio>
            <el-radio :label="1">1</el-radio>
            <el-radio :label="2">2</el-radio>
            <el-radio :label="3">3</el-radio>
          </el-radio-group>
        </div>
      </div>


      <el-button type="primary" @click="onNext" class="bt-next">{{questionType ===0? "下一页":"提交"}}</el-button>
    </div>

    <van-dialog class="result-dialog"
                v-model="isShowAlertDialog"
                title="心理健康检测结果"
                @close="isShowAlertDialog = false"
                confirm-button-color="#348af9">
      <div class="result-hint">您可能是:</div>
      <div class="gad-7-result">{{gadResult}}</div>
      <div class="phq-9-result">{{phqResult}}</div>
      <div class="help-wrapper">
        <div class="help-phone">
          如您需要进行心理咨询，请拨打浙江省统一心理援助热线
          <a href="tel:96525">96525</a>
        </div>
        <div class="help-mini-program" >
          <div class="">或扫码进入 <span style="color: #409eff">湖州心理健康在线</span></div>
          <img class="qr-code" src="../assets/img/qr_code_hzxljkzx.jpg"/>

<!--          <wx-open-launch-weapp-->
<!--              id="launch-btn"-->
<!--              :username="miniProgramUserName"-->
<!--              :path="miniProgramPath"-->
<!--          >-->
<!--            <script type="text/wxtag-template">-->
<!--              <style>.btn { padding: 12px ;color: #409eff}</style>-->
<!--              <div class="btn">或点击进入湖州心理健康在线</div>-->
<!--            </script>-->
<!--          </wx-open-launch-weapp>-->

        </div>
      </div>

    </van-dialog>
  </div>
</template>

<script>
import LocalStorage from "@/store/LocalStorage";
import wx from 'weixin-js-sdk';
export default {
  name: "MentalHealthTesting", //心理健康检测
  components: {
  },
  data() {
    return {
      title: "",
      isShowAlertDialog: false,
      miniProgramUserName: "gh_b717189b73b0",//this.$store.state.miniProgramId_HZJK,
      miniProgramPath: "/pages/index/index",//this.$store.state.miniProgramPath_HZJK,
      questionType: 0, //0: 7项广泛性焦虑障碍量表（GAD-7） 1: 9项抑郁症筛查量表（PHQ-9）
      gad7Questions:[
        "1. 感觉紧张，焦虑或烦躁 ","2. 不能停止或控制担忧","3. 对各种各样的事情担忧过多","4. 很难放松下来",
        "5. 由于不安而无法静坐","6. 变得容易烦恼或急躁","7.害怕将有可怕的事发生"
      ],
      gad7Results:[0,0,0,0,0,0,0],

      phq9Questions:[
        "1. 做什么事都感到没有兴趣或乐趣","2. 感到心情低落","3. 入睡困难、很难熟睡或睡太多","4. 感到疲劳或无精打采",
        "5. 胃口不好或吃太多","6. 觉得自己很早，或很失败，或让自己或家人失望","7. 注意很难集中，例如阅读报纸或看电视",
        "8.动作或说话速度缓慢到别人可察觉的程度，或正好相反——您烦躁或坐立不安，动来动去的情况比平常更严重",
        "9.有不如死掉或用某种方式伤害自己的念头"
      ],
      phq9Results:[0,0,0,0,0,0,0,0,0],
      gad7Grade: 0, //得分结果
      phq9Grade: 0,
      gadResult: "无临床意义的焦虑症状",
      phqResult: "无抑郁症状",
      html:'<wx-open-launch-weapp id="launch-btn" username="gh_b717189b73b0" path="/pages/index/index.html" ' +
          '@launch="handleLaunch" @error="handleError">' +
          '<template><style>.btn { display: flex;align-items: center; }</style>' +
          '<button class="btn">或点击进入湖州心理健康在线平台</button>' +
          '</template></wx-open-launch-weapp>'

    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  mounted() {
    // this.getWxConfig()
    // let btn = document.getElementById('launch-btn');
    // btn.addEventListener('launch', function (e) {
    //   console.log('success');
    // });
    // btn.addEventListener('error', function (e) {
    //   console.log('fail', e.detail);
    // });
    document.addEventListener("visibilitychange",this.handleVisiable)
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange",this.handleVisiable)
  },
  methods: {
    handleVisiable(e) {
      if (e.target.visibilityState === "visible") {

      }else {
        this.isShowAlertDialog = false
        this.$router.back()
      }
    },


    setGadResult(index,result) {
      this.gad7Results[index] = result
    },
    onNext() {
      if (this.questionType === 0) {
        this.questionType = 1
      }else { //计算心理健康检测结果
        for (let i = 0; i < this.gad7Results.length; i++) {
          this.gad7Grade += this.gad7Results[i]
        }
        for (let i = 0; i < this.phq9Results.length; i++) {
          this.phq9Grade += this.phq9Results[i]
        }
        if (this.gad7Grade <= 4) {
          this.gadResult = "无临床意义的焦虑症状"
        }else if (this.gad7Grade >= 5 && this.gad7Grade <=9) {
          this.gadResult = "轻度焦虑症状"
        }else if (this.gad7Grade >=10 && this.gad7Grade <= 14) {
          this.gadResult = "中度焦虑症状"
        }else {
          this.gadResult = "重度焦虑症状"
        }

        if (this.phq9Grade <= 4) {
          this.phqResult = "无抑郁症状"
        }else if (this.phq9Grade >= 5 && this.phq9Grade <=9) {
          this.phqResult = "轻度抑郁症状"
        }else if (this.phq9Grade >=10 && this.phq9Grade <= 14) {
          this.phqResult = "中度抑郁症状"
        }else {
          this.phqResult = "重度抑郁症状"
        }
        let uid = LocalStorage.getUID()
        this.updateGAD7(this.gad7Grade,uid)
        this.updatePHQ9(this.phq9Grade,uid)
//<p>${phqResult}</p>
//         this.$alert(`<p><b>您可能是 ${gadResult},${phqResult}</b></p>
//           <p>如您需要进行心理咨询，请拨打浙江省统一心理援助热线“96525”，或登陆浙里办搜索“护航心理健康”栏目获得帮助。</p>`,"心理健康检测结果",{
//           showClose: false,
//           dangerouslyUseHTMLString: true,
//           callback: action => {
//             this.$router.back()
//           }
//         })
        this.isShowAlertDialog = true

      }
    },
    updateGAD7(psychology7, uid) {
      this.$net.updateGAD7(psychology7,uid).then((res)=> {
        console.log("更新GAD-7",res)
      })
    },
    updatePHQ9(psychology9, uid) {
      this.$net.updatePHQ9(psychology9,uid).then((res)=>{
        console.log("更新PHQ-9",res)
      })
    },
    onClickLeft() {
      this.$router.back()
    },
    handleLaunch() {
      this.isShowAlertDialog = false
      this.$toast.success("success")
    },
    handleError(e) {
      this.isShowAlertDialog = false
      this.$toast.fail("e.detail.errMsg")
    },
    async getWxConfig() {
      let url = window.location.href.split("#")[0]
      let {data} = await this.$net.generateWxSign(url)
      if ("signature" in data) {
        wx.config({
          debug: true, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
          appId: this.$store.state.officialAccount_ZKHZFB, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData','updateTimelineShareData','wx-open-launch-weapp'], // 必填，需要使用的 JS 接口列表
          openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-weapp']
        });
        wx.ready(()=> {
          //config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。

          wx.updateAppMessageShareData({
            title: '新冠居家健康管理', // 分享标题
            desc: '湖州市卫健委，浙江大学湖州研究院', // 分享描述
            link: 'https://covid19.melodicare.cn', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: '', // 分享图标
            success: function () {
              // 设置成功
            }
          })
          wx.updateTimelineShareData({
            title: '新冠居家健康管理', // 分享标题
            // desc: '湖州市卫健委，浙江大学湖州研究院', // 分享描述
            link: 'https://covid19.melodicare.cn', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: '', // 分享图标
            success: function () {
              // 设置成功
            }
          })
          document.addEventListener('WeixinOpenTagsError', function (e) {
            // console.error(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
            this.$toast.fail(e.detail.errMsg)
          });
          var btn = document.getElementById('launch-btn');
          btn.addEventListener('launch', function (e) {
            console.log('success');
          });
          btn.addEventListener('error', function (e) {
            console.log('fail', e.detail);
          })
          // this.$toast.success("wx.ready")
        })
        wx.error(function(res){
          this.$toast.fail(res)
          // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
        });

      }
    },
  }

}
</script>

<style scoped lang="scss">
.mental-health-test {
  .content {
    padding: 20px;
    .question-title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
    }
    .question-hint {
      font-size: 18px;
      margin-bottom: 20px;

    }
    .bt-next {
      width: 100%;
      margin-top: 20px;
    }
  }
  .result-dialog {
    .result-hint {
      margin-left: 16px;
      margin-top: 10px;
      font-weight: 600;
      color: #333333;
    }
    .gad-7-result,.phq-9-result {
      margin-top: 6px;
      margin-left: 32px;
      font-weight: 500;
      color: #666666;
      //color: #409eff;
    }
    .help-wrapper {
      padding-left: 16px;
      padding-right: 16px;
      margin-top: 6px;
      .help-mini-program {
        margin-top: 6px;
        padding-bottom: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        div {
          width: 100%;
        }
        .qr-code {
          width: 150px;
          height: 150px;
        }

      }
    }
  }

}
</style>
