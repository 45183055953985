<template>
  <div class="feedback">
    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <div class="feedback-hint">
        您有什么建议或者问题想对我们说？
      </div>
      <div class="feedback-msg-wrapper">

        <van-field
            v-model="feedbackMsg"
            autosize
            rows="10"
            type="textarea"
            maxlength="300"
            placeholder="告诉我们您的建议或遇到的问题"
            show-word-limit
            clearable
        />
      </div>

      <van-button class="bt-submit" type="info" round @click="uploadFeedback">提交反馈</van-button>




    </div>
  </div>
</template>

<script>
import LocalStorage from "@/store/LocalStorage";

export default {
  name: "Feedback",
  data() {
    return {
      title:"",
      feedbackMsg: "",
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    async uploadFeedback() {
      let uid = LocalStorage.getUID()
      try {
        let data = await this.$net.uploadFeedback(this.feedbackMsg,uid)
        if (data.status === 200) {
          this.$toast.success({
            message:'感谢您的反馈',
            onClose:()=>{
              this.$router.back()
            }
          });

        }else {
          this.$toast.fail("网络出错了")
        }
      }catch (e) {
        this.$toast.fail("网络出错了")
      }
    }
  }
}
</script>

<style scoped lang="scss">
.feedback {
  height: 100%;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #f6f6f6;
    height: 100%;


    .feedback-hint {
      width: 360px;
      margin-top: 16px;
      margin-left: 16px;
      color: #409eff;
      font-size: 18px;
      font-weight: 600px;
    }

    .feedback-msg-wrapper {
      width: 360px;
      background-color: white;
      border-radius: 10px;
      margin: 16px;
      overflow: hidden;

      .van-field {
        font-size: 16px;
      }

    }
    .bt-submit {
      width: 320px;
      margin-top: 20px;

    }
  }
}

</style>
