<template>
  <div class="vital-signs-monitor">

    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          @click-right="goPersonInfoSummary"
          fixed
          placeholder>

        <template #right>
           <van-icon name="notes-o" size="24"/>
        </template>

      </van-nav-bar>
    </div>

    <div class="content">
      <el-form v-if="false" ref="vitalSigns" :modle="vitalSignsForm" :rules="rules" label-width="auto" :inline="false">
        <el-form-item label="抗原结果">
          <el-radio-group v-model="vitalSignsForm.covidAntigen" >
            <el-radio :label="0">阴性</el-radio>
            <el-radio :label="1">阳性</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="核酸结果">
          <el-radio-group v-model="vitalSignsForm.nucleicAcid" >
            <el-radio :label="0">阴性</el-radio>
            <el-radio :label="1">阳性</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="体温" prop="bodyTemp" >
          <el-input  v-model="vitalSignsForm.bodyTemp">
            <template slot="append">℃</template>
          </el-input>
        </el-form-item>

        <el-form-item label="心率" prop="heartRate">
          <el-input v-model="vitalSignsForm.heartRate">
            <template slot="append">bpm</template>
          </el-input>
        </el-form-item>

        <el-form-item label="血压">
          <el-input v-model="vitalSignsForm.systolic">
            <template slot="prepend">收缩压</template>
            <template slot="append">mmHg</template>
          </el-input>
          <el-input v-model="vitalSignsForm.diastolic">
            <template slot="prepend">舒张压</template>
            <template slot="append">mmHg</template>
          </el-input>
        </el-form-item>

        <el-form-item label="血糖" prop="bloodGlucose">
          <el-input v-model="vitalSignsForm.bloodGlucose">
            <template slot="append">mmol/l</template>
          </el-input>
        </el-form-item>

        <el-form-item label="血氧饱和度" prop="spo2">
          <el-input v-model="vitalSignsForm.spo2"></el-input>
        </el-form-item>

        <el-form-item label="呼吸率" prop="breathResp">
          <el-input v-model="vitalSignsForm.breathResp"></el-input>
        </el-form-item>

        <el-form-item size="large">
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>


      </el-form>


      <van-form >


        <van-field name="covidAntigen" label="抗原结果">
          <template #input>
            <van-radio-group v-model="vitalSignsForm.covidAntigen" direction="horizontal">
              <van-radio :name="0">阴性</van-radio>
              <van-radio :name="1">阳性</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field name="nucleicAcid" label="核酸结果">
          <template #input>
            <van-radio-group v-model="vitalSignsForm.nucleicAcid" direction="horizontal">
              <van-radio :name="0">阴性</van-radio>
              <van-radio :name="1">阳性</van-radio>
            </van-radio-group>
          </template>
        </van-field>


        <van-field
            v-model="vitalSignsForm.bodyTemp"
            center
            label="体温"
            placeholder="请输入体温"
            type="number"
            name="btRule"
            :rules="[{ validator:btRule, message: '请输入正确的体温' ,trigger:'onBlur'}]">
          <template #button>
            <span>℃</span>
          </template>
        </van-field>
        <van-field
            v-model="vitalSignsForm.heartRate"
            center
            clearable
            label="心率"
            placeholder="请输入心率"
            type="digit"
            name="hrRule"
            :rules="[{ validator:hrRule, message: '请输入正确的心率' ,trigger:'onBlur'}]">
          <template #button>
            <span>bpm</span>
          </template>
        </van-field>
        <van-field
            v-model="vitalSignsForm.systolic"
            center
            clearable
            label="收缩压"
            placeholder="请输入收缩压"
            type="digit"
            name="sbpRule"
            :rules="[{ validator:sbpRule, message: '请输入正确的收缩压' ,trigger:'onBlur'}]">
          <template #button>
            <span>mmHg</span>
          </template>
        </van-field>
        <van-field
            v-model="vitalSignsForm.diastolic"
            center
            clearable
            label="舒张压"
            placeholder="请输入舒张压"
            type="digit"
            name="dbpRule"
            :rules="[{ validator:dbpRule, message: '请输入正确的舒张压' ,trigger:'onBlur'}]">
          <template #button>
            <span>mmHg</span>
          </template>
        </van-field>

        <van-field
            v-model="vitalSignsForm.bloodGlucose"
            center
            clearable
            label="血糖"
            placeholder="请输入血糖"
            type="number"
            name="bgRule"
            :rules="[{ validator:bgRule, message: '请输入正确的血糖值' ,trigger:'onBlur'}]">
          <template #button>
            <span>mmol/L</span>
          </template>
        </van-field>

        <van-field
            v-model="vitalSignsForm.spo2"
            center
            clearable
            label="血氧饱和度"
            placeholder="请输入血氧饱和度"
            type="number"
            name="spo2Rule"
            :rules="[{ validator:spo2Rule, message: '请输入正确的血压值' ,trigger:'onBlur'}]">
          <template #button>
            <span>%</span>
          </template>
        </van-field>

        <van-field
            v-model="vitalSignsForm.breathResp"
            center
            clearable
            label="呼吸率"
            placeholder="请输入呼吸率"
            type="number"
            name="respRateRule"
            :rules="[{ validator:respRateRule, message: '请输入正确的血压值' ,trigger:'onBlur'}]">
        </van-field>


        <div style="margin: 16px;">
          <van-button round block type="info"  @click="onSubmit">提交</van-button>
        </div>


      </van-form>


    </div>


  </div>
</template>

<script>
import LocalStorage from "@/store/LocalStorage";
import moment from "moment";
export default {
  name: "VitalSignsMonitoring", //生命体征监测页面
  data() {

    return {
      title: "",
      vitalSignsForm: {
        covidAntigen: 0, //抗原 0阴性 1阳性
        nucleicAcid: 0,//核酸 0阴性 1阳性
        bodyTemp: "",
        heartRate: "",
        systolic:"",
        diastolic:"",
        bloodGlucose: "",
        spo2: "",
        breathResp: "",

      },
      btRules:"",

      rules: {

      }
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    onSubmit() {
      if (this.vitalSignsForm.covidAntigen === 1 || this.vitalSignsForm.nucleicAcid === 1) {
        this.$store.state.userInfo.isCovid19 = 1;
      }
      if (this.vitalSignsForm.covidAntigen === 0 && this.vitalSignsForm.nucleicAcid === 0) {
        this.$store.state.userInfo.isCovid19 = 0;
        this.$store.state.userInfo.isSevereSymptoms = false
      }
      let time = moment()
      let measureInfoParam = {
        antigenResult:this.vitalSignsForm.covidAntigen,
        bloodGlucose: this.vitalSignsForm.bloodGlucose,
        bodyTemp: this.vitalSignsForm.bodyTemp,
        br: this.vitalSignsForm.breathResp,
        diastolic: this.vitalSignsForm.diastolic,
        systolic: this.vitalSignsForm.systolic,
        heartRate: this.vitalSignsForm.heartRate,
        nucleicAcidResult: this.vitalSignsForm.nucleicAcid,
        spo2: this.vitalSignsForm.spo2,
        uid: LocalStorage.getUID(),
        year:time.year(),
        month:time.month()+1,
        day: time.date(),
      }
      this.uploadVitalSigns(measureInfoParam)
      this.queryLast3DaysHistory()

    },

    checkAbnormalResult() {
      if (this.vitalSignsForm.systolic > 140 || this.vitalSignsForm.diastolic > 90 || this.vitalSignsForm.bloodGlucose > 7) {
        this.$dialog.alert({
          message:"异常值！建议您再次监测，或连续三天监测",
          title: "提示"
        },).then(()=>{
          if (this.$store.state.userInfo.isCovid19 === 1
              || this.vitalSignsForm.bodyTemp > 38.5
              || this.vitalSignsForm.breathResp > 28 || (this.vitalSignsForm.spo2!== "" && this.vitalSignsForm.spo2 < 94)) {
            this.$router.replace({
              path: "/severeSymptomsAssessment",
              query: {
                from: this.$route.fullPath
              }
            })
          }else {
            this.$router.back()
          }
        })
      }else {
        if (this.$store.state.userInfo.isCovid19 === 1
            || this.vitalSignsForm.bodyTemp > 38.5
            || this.vitalSignsForm.breathResp > 28 || this.vitalSignsForm.spo2 < 94) {
          this.$router.replace({
            path: "/severeSymptomsAssessment",
            query: {
              from: this.$route.fullPath
            }
          })
        }else {
          this.$router.back()
        }
      }
    },

    async queryLast3DaysHistory() {
      let uid = LocalStorage.getUID()
      let today = moment()
      let beforeYesterday = today.clone().subtract(2,"days")
      let yesterday = today.clone().subtract(1,"days")
      try {
        let {data:{data:todayResult}} = await this.$net.queryMeasureResultByDate(today.year(),today.month()+1,today.date(),uid)
        let {data:{data:beforeYesterdayResult}} = await this.$net.queryMeasureResultByDate(beforeYesterday.year(),beforeYesterday.month()+1,beforeYesterday.date(),uid)
        let {data:{data:yesterdayResult}} = await this.$net.queryMeasureResultByDate(yesterday.year(),yesterday.month()+1,yesterday.date(),uid)
        console.log(todayResult,beforeYesterdayResult,yesterdayResult)
        let bpAbnormal = [false,false,false];
        let bgAbnormal = [false,false,false];

        for (let i = 0; i < todayResult.length; i++) {
          if (todayResult[i].systolic > 140 || todayResult[i].diastolic > 90) {
            bpAbnormal[0] = true
          }
          if (todayResult[i].bloodGlucose > 7) {
            bgAbnormal[0] = true
          }
        }
        for (let i = 0; i < beforeYesterdayResult.length; i++) {
          if (beforeYesterdayResult[i].systolic > 140 || beforeYesterdayResult[i].diastolic > 90) {
            bpAbnormal[1] = true
          }
          if (beforeYesterdayResult[i].bloodGlucose > 7) {
            bgAbnormal[1] = true
          }
        }
        for (let i = 0; i < yesterdayResult.length; i++) {
          if (yesterdayResult[i].systolic > 140 || yesterdayResult[i].diastolic > 90) {
            bpAbnormal[2] = true
          }
          if (yesterdayResult[i].bloodGlucose > 7) {
            bgAbnormal[2] = true
          }
        }
        console.log(bgAbnormal,bpAbnormal)
        //判断是否连续三天异常
        if (bpAbnormal.indexOf(false) === -1 || bgAbnormal.indexOf(false) === -1) {
          console.log("连续三天异常")
          this.$dialog.confirm({
            title: "提示",
            message: "根据您纪录的健康数据，建议您重新评估您的基础病情况",
            confirmButtonText: "重新评估",
            confirmButtonColor: "#348af9"
          }).then(()=> {
            this.$router.push({
              path: "/riskGradeAssessment",
              query: {
                isAccountExist: true
              }
            })
          }).catch(()=> {
            this.checkAbnormalResult()
          })
        }else {
          this.checkAbnormalResult()
        }
      }catch (e) {
        console.log(e)
        this.checkAbnormalResult()
      }


    },

    uploadVitalSigns(measureInfoParam) {
      this.$net.uploadVitalSigns(measureInfoParam).then((res)=> {
        console.log("uploadVitalSigns",res.data)
      })
    },
    onClickLeft() {
      this.$router.back()
    },
    goPersonInfoSummary() {
      this.$router.push({
        name:"PersonInfoSummary"
      })
    },
    btRule(val) {
      if (val === undefined || val === "") {
        return true
      }
      if (Number.isNaN(val)) {
        this.vitalSignsForm.bodyTemp = ''
        return false
      }else {
        if (val < 32 || val > 43) {
          this.vitalSignsForm.bodyTemp = ''
          return false
        }else {
          return true
        }
      }
    },
    hrRule(val) {
      if (val === undefined || val === "") {
        return true
      }
      if (Number.isNaN(val)) {
        this.vitalSignsForm.heartRate = ''
        return false
      }else {
        if (val < 30 || val > 220) {
          this.vitalSignsForm.heartRate = ''
          return false
        }else {
          return true
        }
      }
    },
    sbpRule(val) {
      if (val === undefined || val === "") {
        return true
      }
      if (Number.isNaN(val)) {
        this.vitalSignsForm.systolic = ''
        return false
      }else {
        if (val < 30 || val > 300) {
          this.vitalSignsForm.systolic = ''
          return false
        }else {
          return true
        }
      }
    },
    dbpRule(val) {
      if (val === undefined || val === "") {
        return true
      }
      if (Number.isNaN(val)) {
        this.vitalSignsForm.diastolic = ''
        return false
      }else {
        if (val < 30 || val > 300) {
          this.vitalSignsForm.diastolic = ''
          return false
        }else {
          return true
        }
      }
    },
    bgRule(val) {
      if (val === undefined || val === "") {
        return true
      }
      if (Number.isNaN(val)) {
        this.vitalSignsForm.bloodGlucose = ''
        return false
      }else {
        if (val < 0 || val > 20) {
          this.vitalSignsForm.bloodGlucose = ''
          return false
        }else {
          return true
        }
      }
    },
    spo2Rule(val) {
      if (val === undefined || val === "") {
        return true
      }
      if (Number.isNaN(val)) {
        this.vitalSignsForm.spo2 = ''
        return false
      }else {
        if (val < 70 || val > 100) {
          this.vitalSignsForm.spo2 = ''
          return false
        }else {
          return true
        }
      }
    },
    respRateRule(val) {
      if (val === undefined || val === "") {
        return true
      }
      if (Number.isNaN(val)) {
        this.vitalSignsForm.breathResp = ''
        return false
      }else {
        if (val < 0 || val > 60) {
          this.vitalSignsForm.breathResp = ''
          return false
        }else {
          return true
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.vital-signs-monitor {
  .content {
    padding: 20px;
    overflow-y: scroll;
  }

}
</style>
