<template>
  <div class="news-history">
    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <div class="news-wrapper">
        <div class="news-item" v-for="(news,index) in newsList" :key="news.url" @click="onNewsClicked(index)">
          <div class="left">
            <div class="news-title">
              {{news.title}}
            </div>
            <div class="publish-time">
              {{news.publishTime}}
            </div>
          </div>
          <div class="right" >
            <img class="news-img" :src="news.imgPath" alt="">
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "NewsHistory",
  data() {
    return {
      title:"",
      newsList: [

        {
          title:"退烧不要仅紧盯布洛芬，看看其他的选择？",
          url: "https://mp.weixin.qq.com/s/FiJApZoMhpn3Nh0VKhcpMw",
          publishTime: "2022-12-20 19:59",
          author: "浙一临床药学部",
          imgPath:require("../assets/img/pic_news_6.jpeg"),
        },

        {
          title:"家里婴幼儿、儿童、孕妇、哺乳期妈妈“阳”了，如何用药？",
          url: "https://mp.weixin.qq.com/s/FlHDtxHmKSuH2OC9Z4D-YA",
          publishTime: "2022-12-20 19:59",
          author: "浙一临床药学部",
          imgPath: require("../assets/img/pic_news_5.jpeg"),

        },

        {
          title:"感染后“刀片嗓”如何快速缓解？专家解答！",
          url: "https://mp.weixin.qq.com/s/FlHDtxHmKSuH2OC9Z4D-YA",
          publishTime: "2022-12-19 20:30",
          author: "健康湖州发布",
          imgPath: require("../assets/img/pic_news_4.jpeg"),
        },

        {
          title:"新冠感染后的7天，我是这样度过的",
          url: "https://mp.weixin.qq.com/s/UfJtSis13JZwAXxB0UxUXw",
          publishTime: "2022-12-19 08:57",
          author: "人民日报",
          imgPath: require("../assets/img/ic_rmrbwx.jpeg"),
        },

        {
          title:"湖州市新冠病毒感染者居家中医药健康管理专家指南",
          url: "https://mp.weixin.qq.com/s/bDwJU-hI5QcUoSAzREgK2w",
          publishTime: "2022-12-13 23:03",
          author: "健康湖州发布",
          imgPath: require("../assets/img/news_publish.png"),
        },
        {
          title: "第二剂次加强疫苗来了！这些人群可接种",
          url: "https://mp.weixin.qq.com/s/Csa8i8XJ1PYwi7O_TQwYzA",
          publishTime: "2022-12-14 14:52",
          author: "健康湖州发布",
          imgPath: require("../assets/img/news_publish.png"),
        },
      ],
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onNewsClicked(index) {
      window.open(this.newsList[index].url)
    },
  }
}
</script>

<style scoped lang="scss">
.news-history {
  height: 100%;
  .content {
    height: calc(100% - 46px);
    background-color: #f6f6f6;
    padding: 16px;

    .news-wrapper {
      border-radius: 10px;
      height: 100%;
      background-color: white;
      .news-item {
        display: flex;
        border-bottom: 1px solid #DDDDDD;
        .left {
          padding: 16px;
          .news-title {
            color: #333333;
            font-size: 18px;
          }
          .publish-time {
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
          }
        }

        .right {
          padding: 16px 16px 16px 0px;
          .news-img {
            width: 68px;
            height: 68px;
            object-fit: cover;
          }
        }
      }
    }
  }
}

</style>

