import axios from "axios";
import qs from "qs";

const isDebug = false;
const BASE_URL = "http://vision.mintti.cn/vision/";
const base_url = isDebug? "http://192.168.0.29:8080/covid-19":"https://covid19.melodicare.cn/covid-19"
const http = axios.create({
  baseURL:base_url,
  timeout: 10000 //请求超时10s
});
const wx = axios.create({
  baseURL:"/wx"
})

export default {

  getWxCode() {
    let appid = "wx50edf76bc2543f7b"
    let redirect_uri = "http%3A%2F%2Fcovid19.melodicare.cn"
    return wx.get(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx50edf76bc2543f7b&redirect_uri=https%3A%2F%2Fcovid19.melodicare.cn&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`)
  },

  getAssessToken(code) {
    return http.get("/wechat/getAccessToken",{
      params: {
        code
      }
    })
  },
  refreshToken(refreshToken) {
    return http.get("/wechat/refreshToken",{
      params: refreshToken
    })
  },
  getWxUserInfo(accessToken,openid) {
    return http.get("/wechat/getWechatUserInfo",{
      params: {
        accessToken,
        openid
      }
    })
  },
  generateWxSign(url) {
    return http.get("/wechat/generateSign",{
      params: {
        url
      }
    })
  },



  createUser(userInfoParam) {
    return  http.post("/user/create",userInfoParam)
  },

  getUserInfoByOpenId(openId) {
    return http.get("/user/info/openId",{
      params:{
        openId
      }
    })
  },

  getUserInfo() {

  },
  //更新特殊人群
  updateCrowd(isSpecialCrowd,uid) {
    return http.post("/user/update/crowd",{},{
      params: {
        isSpecialCrowd,
        uid
      }
    })
  },
  //更新基础疾病
  updateBasicDisease(disease,uid) {
    return http.post("/user/update/disease",{},{
      params: {
        disease,
        uid
      }
    })
  },
  //更新核酸信息
  updateNucleic(isPositive,isSevere,uid) {
    return http.post("/user/update/nucleic",{},{
      params: {
        isPositive,
        isSevere,
        uid
      }
    })
  },
  updateGAD7(psychology7,uid) {
    return http.post("/user/update/psychology7",{},{
      params: {
        psychology7,
        uid
      }
    })
  },
  updatePHQ9(psychology9,uid) {
    return http.post("/user/update/psychology9",{},{
      params: {
        psychology9,
        uid
      }
    })
  },
  //更新人员风险等级
  updateRiskLevel(riskLevel,uid) {
    return http.post("/user/update/riskLevel",{},{
      params: {
        riskLevel,
        uid
      }
    })
  },
  //更新疫苗信息
  updateVero(isVero,uid) {
    return http.post("/user/update/vero",{},{
      params: {
        isVero,
        uid
      }
    })
  },

  updateAgeRange(ageScope,uid) {
    return http.post("/user/update/ageScope",{},{
      params: {
        ageScope,
        uid
      }
    })
  },


  //上传
  uploadVitalSigns(measureInfoParam) {
    return http.post("/measure/upload",measureInfoParam)
  },


  getMeasureHistory(page,rows=10,uid) {
    return  http.get(`/measure/query/${page}/${rows}`,{
      params: {
        uid
      }
    })
  },
  queryMeasureResultByDate(year,month,day,uid) {
    return http.get("/measure/query/date",{
      params: {
        year,
        month,
        day,
        uid
      }
    })
  },
  refreshRiskLevel(ageScope,disease,isPositive,isSevere,isSpecialCrowd,isVero,riskLevel,uid) {
    return  http.post("/user/refresh/riskLevel",{},{
      params: {
        ageScope,
        disease,
        isPositive,
        isSevere,
        isSpecialCrowd,
        isVero,
        riskLevel,
        uid
      }
    })
  },
  uploadFeedback(feedback,uid) {
    return http.post("/feedback/upload",{},{
      params: {
        feedback,
        uid
      }
    })
  }


};
