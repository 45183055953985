<template>
  <div class="hotline">
    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <div class="hotline-title">
        <p>感染了新冠病毒不一定要去医院，无症状和症状轻微、无严重基础疾病的阳性患者，可以优先居家隔离治疗。
          居家治疗期间遇到疑问怎么办？
        </p>
        <p>欢迎您拨打各区县的暖心热线进行咨询。</p>
        <p>
          咨询内容：新冠阳性居家隔离治疗指导、医疗机构发热门诊（诊室）就诊流程及其他相关科普咨询等内容。
        </p>
      </div>

      <div class="hotline-list">
        <div class="hotline-desc">各区暖心热线</div>
        <div class="hotline-district-item" v-for="hotlineDistrict in HuZhouHotline.districts" :key="hotlineDistrict.districtName">
          <div class="district-name">{{hotlineDistrict.districtName}}</div>
          <div class="hotline-wrapper">
            <div class="district-hospitals" v-if="hotlineDistrict.hospitals.length>0">
              <div class="hospital-item" v-for="hospital in hotlineDistrict.hospitals" :key="hospital.hospitalName">
                <div class="hospital-name">{{hospital.hospitalName}}</div>
                <div class="hospital-phone-wrapper">
                  <div class="hospital-phone" v-for="phone in hospital.hospitalPhones" :key="phone">
                    <a :href="'tel:'+phone">{{phone}}</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="district-hotline" v-else>
              <a :href="'tel:'+hotlineDistrict.districtPhone">{{hotlineDistrict.districtPhone}}</a>
            </div>
          </div>
        </div>
      </div>



    </div>



  </div>
</template>

<script>
export default {
  name: "Hotline",
  data() {
    return {
      title: "",
      HuZhouHotline: {
        districts:[
          {
            districtName:"吴兴区",
            districtPhone: "0572-2551133",
            hospitals:[]
          },
          {
            districtName:"长兴县",
            districtPhone: "0572-6800120",
            hospitals:[]
          },
          {
            districtName:"安吉县",
            districtPhone: "18957280897",
            hospitals:[]
          },
          {
            districtName:"南浔区",
            districtPhone: "",
            hospitals:[
              {
                hospitalName: "南浔区人民医院",
                hospitalPhones: ["18957280897"],
              },
              {
                hospitalName: "南浔区中医院" ,
                hospitalPhones: ["0572-3016120"],
              },
              {
                hospitalName: "菱湖人民医院",
                hospitalPhones: ["0572-2980229"],
              },
              {
                hospitalName: "双林人民医院",
                hospitalPhones: ["13819239700"],
              },
              {
                hospitalName: "练市人民医院",
                hospitalPhones: ["15088326120"],
              },
              {
                hospitalName:"心理咨询热线",
                hospitalPhones: ["0572-3036009"],
              },

            ]
          },
          {
            districtName:"德清县",
            districtPhone: "",
            hospitals:[
              {
                hospitalName: "德清县人民医院",
                hospitalPhones: ["0572-8298519","0572-8298331"],
              },
              {
                hospitalName: "德清县中医院",
                hospitalPhones: ["0572-8223710"],
              },
              {
                hospitalName: "德清县第三人民医院",
                hospitalPhones: ["0572-8440521"],
              },
              {
                hospitalName: "乾元镇中心卫生院",
                hospitalPhones: ["0572-8425062"],
              },
              {
                hospitalName: "新市镇中心卫生院",
                hospitalPhones: ["0572-8061030"],
              },
              {
                hospitalName: "钟管镇中心卫生院",
                hospitalPhones: ["0572-8401558"],
              },
              {
                hospitalName: "洛舍镇卫生院",
                hospitalPhones: ["0572-8359973","13957277371"],
              },
              {
                hospitalName: "雷甸镇卫生院",
                hospitalPhones: ["0572-8485021"],
              },
              {
                hospitalName: "禹越镇卫生院",
                hospitalPhones: ["0572-8468247"],
              },
              {
                hospitalName: "新安镇卫生院",
                hospitalPhones: ["0572-8461037"],
              },
              {
                hospitalName: "莫干山镇卫生院庾村院区",
                hospitalPhones: ["0572-8051127"],
              },
              {
                hospitalName: "莫干山镇卫生院筏头院区",
                hospitalPhones: ["0572-8051127"],
              },
              {
                hospitalName: "武康街道社区卫生服务中心",
                hospitalPhones: ["0572-8378761"],
              },
              {
                hospitalName: "舞阳街道社区卫生服务中心",
                hospitalPhones: ["0572-8012939"],
              },
              {
                hospitalName: "阜溪/康乾街道社区卫生服务中心",
                hospitalPhones: ["0572-8021014"],
              },
              {
                hospitalName: "下渚湖街道社区卫生服务中心",
                hospitalPhones: ["0572-8481119"],
              },
            ]
          }
        ]
      }
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.hotline {

  .content {
    padding: 10px;
  }
  background-color: #f6f6f6;

  .hotline-title {
    background-color: white;
    border-radius: 10px;
    padding: 8px;
    color: #666666;
  }

  .hotline-list {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border-radius: 10px;
    background-color: white;
    margin-top: 10px;
    .hotline-desc {
      color: #333333;
      text-align: center;
      margin-top: 10px;
      font-weight: 500;
    }

    .hotline-district-item {

      padding: 10px;
      margin-top: 10px;
      margin-bottom: 10px;


      .district-name {
        padding: 4px;
        color: #1663CD;
        border: 1px solid #999999;
        border-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .district-hotline {
        padding: 4px;
        border: 1px solid #999999;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          color: #666666;

        }
      }
      .hotline-wrapper {
        .district-hospitals {
          height: 100%;
          font-size: 16px;
          .hospital-item {
            height: 100%;
            display: flex;
            &:last-child {
              .hospital-name{
                border-bottom: 1px solid #999999;
              }
              .hospital-phone-wrapper {
                .hospital-phone {
                  border-bottom: 1px solid #999999;
                }
              }

            }
            .hospital-name {
              width: 188px;
              border: 1px solid #999999;
              border-bottom: 0;
              border-right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 4px;

            }


            .hospital-phone-wrapper {
              flex: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .hospital-phone {
                height: 100%;
                padding: 4px;
                border: 1px solid #999999;
                border-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                  color: #666666;

                }

              }

            }

          }

        }
      }



    }



  }
}
</style>
