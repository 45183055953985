import { render, staticRenderFns } from "./MentalHealthTesting.vue?vue&type=template&id=4e092ed8&scoped=true&"
import script from "./MentalHealthTesting.vue?vue&type=script&lang=js&"
export * from "./MentalHealthTesting.vue?vue&type=script&lang=js&"
import style0 from "./MentalHealthTesting.vue?vue&type=style&index=0&id=4e092ed8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e092ed8",
  null
  
)

export default component.exports