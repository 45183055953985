<template>
  <div class="vero-column">
    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">

      <div class="vero-news-banner">

        <van-swipe class="vero-news-swipe" :autoplay="5000" >
          <van-swipe-item class="news-item" v-for="(news,index) in veroNews" :key="news.url" @click="onNewsClicked(index)">

              <div class="left">
                <div class="news-title van-multi-ellipsis--l2" >
                  {{news.title}}
                </div>
                <div class="publish-time">
                  {{news.publishTime}}
                </div>
              </div>
              <div class="right" >
                <img class="news-img" :src="news.imgPath" alt="">
              </div>



          </van-swipe-item>

        </van-swipe>


      </div>


      <div class="contraindication-wrapper">
        <h3>老年人接种新冠疫苗有四类禁忌，国家疾控局详解</h3>
        <p>目前新冠疫苗的接种禁忌有哪些？</p>
        <p>老年人接种疫苗需要注意什么？</p>
        <p>一起来了解↓</p>

        <p>国家疾控局卫生免疫司司长夏刚表示，老年人的接种禁忌包括四类：</p>


        <p>&emsp;&emsp;一是既往接种疫苗时曾发生过严重的过敏反应，主要是过敏性休克和喉头水肿。</p>

        <p>&emsp;&emsp;二是因严重慢性疾病生命已经进入了终末阶段。</p>
        <p>&emsp;&emsp;三是缓种，急性感染性疾病处于发热阶段，这个时候要暂缓接种。</p>
        <p>&emsp;&emsp;四是严重的慢性疾病处于急性发作期，要暂缓接种。比如正在化疗的肿瘤患者，出现高血压危象的患者，冠心病患者心梗发作，
          以及自身免疫性神经系统疾病处于进展期，癫痫患者处于发作期。</p>
        <p>转自：《朝闻天下》 <br/>来源： 央视一套</p>
        <p></p>
      </div>










    </div>
  </div>
</template>

<script>
export default {
  name: "VeroColumn",
  data() {
    return {
      title:"",
      veroNews: [
        {
          title:"最新！全市新冠疫苗接种点一览表",
          url: "https://mp.weixin.qq.com/s/sOYxX_9jzQ9uuvNtffUgAw",
          publishTime: " 2022-12-06 18:50",
          source: "湖州发布",
          imgPath:require("../assets/img/ic_vaccination_position.jpeg"),
        },
        {
          title:"打完第三针加强针，还需要打第四针吗？打疫苗不如感染一次？热点疑问，湖博士为您解答",
          url: "https://mp.weixin.qq.com/s/oStdBZ3QaLJwzkseQZWPUg",
          publishTime: " 2022-12-16 18:51",
          source: "健康湖州发布",
          imgPath:require("../assets/img/ic_vaccination.jpeg"),
        }
      ],
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onNewsClicked(index) {
      window.open(this.veroNews[index].url)
    }
  }
}
</script>

<style scoped lang="scss">
.vero-column {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
  height: 100%;
  .content {
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    .vero-news-banner {
      border-radius: 10px;
      background-color: white;
      .news-item {
        display: flex;
        .left {
          padding: 16px;
          .news-title {
            color: #333333;
            font-size: 18px;
          }
          .publish-time {
            color: #999999;
            font-size: 14px;
            margin-top: 10px;
          }
        }
        .right {
          padding: 16px 16px 16px 0px;
          .news-img {
            width: 68px;
            height: 68px;
          }
        }
      }
    }

    .contraindication-wrapper {
      border-radius: 10px;
      margin-top: 20px;
      padding: 16px;
      background-color: white;
    }


  }




}


</style>
