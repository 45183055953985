<template>
  <div class="med-guide">

    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <h3>同类型药物不可同时服用，具体用法请参考说明书</h3>
      <div class="otc-med-content">
        <div class="otc-title">OTC药物用药指导：用法用量详见说明书</div>

        <table border="1">
          <tr>
            <th>临床表现</th>
            <th>推荐意见</th>
          </tr>
          <tr v-for="(med,index) in otcMedGuide" :key="med.symptoms">
            <td>{{med.symptoms}}</td>
            <td>{{med.medGuide}}</td>
          </tr>



        </table>


<!--        <el-table :data="otcMedGuide" border>-->
<!--          <el-table-column-->
<!--              prop="symptoms"-->
<!--              label="临床表现">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--              prop="medGuide"-->
<!--              label="推荐意见">-->
<!--          </el-table-column>-->
<!--        </el-table>-->
      </div>

      <div class="chinese-med-content">
        <div class="chinese-med-title">中成药用药指引：用法用量详见说明书</div>
        <table border="1">
          <tr>
            <th>分型</th>
            <th>临床表现</th>
            <th>推荐意见</th>
          </tr>
          <tr v-for="(med,index) in chineseMedGuide" :key="med.symptoms">
            <td :rowspan="index===0?4: index===4?3:1" v-if="index===0?true: index===4">{{med.type}}</td>
            <td>{{med.symptoms}}</td>
            <td>{{med.medGuide}}</td>
          </tr>



        </table>
<!--        <el-table :data="chineseMedGuide" border >-->
<!--          <el-table-column-->
<!--              prop="type"-->
<!--              label="分型">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--              prop="symptoms"-->
<!--              label="临床表现">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--              prop="medGuide"-->
<!--              label="推荐意见">-->
<!--          </el-table-column>-->
<!--        </el-table>-->
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "MedGuide", //用药指引界面
  data() {
    return {
      title: "",
      otcMedGuide: [
        {
          symptoms:"发热",
          medGuide: "对乙酰氨基酚 或 布洛芬或阿司匹林",
        },
        {
          symptoms:"咽干咽痛",
          medGuide: "清咽滴丸 或 地喹氯铵",
        },
        {
          symptoms:"咳嗽咳痰",
          medGuide: "溴己新或氨溴索或愈创甘油醚 或 乙酰半胱氨酸",
        },
        {
          symptoms:"干咳无痰",
          medGuide: "右沙美芬 或 福尔可定",
        },
        {
          symptoms:"流鼻涕打喷嚏",
          medGuide: "氯苯那敏 或 氯雷他定 或 西替利嗪",
        },
        {
          symptoms:"鼻塞",
          medGuide: "赛洛唑啉滴鼻剂等",
        },
        {
          symptoms:"恶心呕吐",
          medGuide: "藿香正气胶囊 或桂利嗪等"
        },
      ],
      chineseMedGuide:[
        {
          type:"轻型",
          symptoms:"以乏力伴肠胃不适，腹泻为主",
          medGuide: "藿香正气口服液(水、丸、片、胶囊、颗粒、软胶囊)",
        },
        {
          type:"轻型",
          symptoms:"以发热、咽痛、乏力为主",
          medGuide: "金花清感颗粒、抗病毒颗粒(口服液)、疏风解毒胶囊、双清合剂、柴银颖粒、克感利咽口服液",
        },
        {
          type:"轻型",
          symptoms:"以高热、乏力、便秘为主",
          medGuide: "连花清瘟胶囊(颗粒)、柴石退热颗粒、防风通圣丸(颗粒)",
        },
        {
          type:"轻型",
          symptoms:"以周身酸痛、发热、恶寒、乏力、无汗时",
          medGuide: "荆防颗粒、九味羌活颗粒(丸)",
        },
        {
          type:"普通型(湿邪郁肺证、湿热蕴肺证、热毒袭肺证)",
          symptoms:"以发热、周身酸痛、咳嗽、咽痛、气短为主",
          medGuide: "连花清瘟胶囊(颗粒)、金花清感颗粒、甘露消毒丸、抗病毒颗粒(口服液)",
        },
        {
          type:"普通型(湿邪郁肺证、湿热蕴肺证、热毒袭肺证)",
          symptoms:"以咳嗽、胸闷、咯吐黄脓痰为主",
          medGuide: "清肺消炎丸、清肺宁嗤丸、痰热清胶囊",
        },
        {
          type:"普通型(湿邪郁肺证、湿热蕴肺证、热毒袭肺证)",
          symptoms:"以高热、咽痛、口苦、便秘、胸闷气短为主",
          medGuide: "柴石退热颗粒",
        }
      ],
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log(`row: ${row} col: ${column} rowIndex: ${rowIndex} colIndex: ${columnIndex}`)
      // if (columnIndex === 0) {
      //   if (rowIndex === 1) {
      //     return {
      //       rowspan: 1,
      //       colspan: 0
      //     };
      //   }
      // }
    },
    onClickLeft() {
      this.$router.back()
    },
  }
}
</script>

<style scoped lang="scss">
.med-guide{

  .content {
    h3 {
      margin: 4px 0 10px 10px;
    }

    background-color: #f6f6f6;
    .otc-med-content {
      border-radius: 10px;
      margin: 10px;
      padding: 10px;
      background-color: white;
      .otc-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }
    }
    .chinese-med-content {
      border-radius: 10px;
      margin: 10px;
      padding: 10px;
      background-color: white;
      .chinese-med-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
    table {
      border-collapse: collapse;
      tr{
        width: 100%;
      }
      th {
        padding: 4px;
        text-align: center;
      }
      td {
        padding: 4px;
        text-align: center;
      }
    }
  }

}
</style>
