<template>
  <div class="follow-up-suggestion">
    请联系您所在的社区卫生服务中心获取随访建议。

  </div>

</template>

<script>
export default {
  name: "FollowUpSuggestions" //随访建议
}
</script>

<style scoped lang="scss">
.follow-up-suggestion {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 20px;
}
</style>