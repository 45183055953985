export const store = {
    state: {
        userInfo: {
            nickname:"",
            name: "",
            gender: 1,
            age: "",
            ageRange: "<65",  // <65 65-79 ≥80
            ageScope: 0,  //0 <65  1 65-79  2 >=80
            idNum: "",
            residentialAddress: "",
            phoneNum: "",
            emergencyContactName:"",
            emergencyContactPhoneNum: "",
            isCovid19: 0, //0未感染 1 已感染
            covidAntigen: 0, //抗原 0阴性 1阳性
            nucleicAcid: 0,//核酸 0阴性 1阳性
            riskGradeAssessment: {
                vaccination:1, //疫苗接种情况  0 未全程接种 1 全程接种
                basicDiseases: 0, //基础疾病情况  0 无基础疾病 1 有基础疾病稳定 2 基础疾病不稳定
                specialPopulationType:0, //0 非特殊人群 1 孕妇及哺乳期 2 血透析患者 3 空巢独居老人 4 放化疗患者 5 失能 6 其他
                specialPopulationTypeNote: "", //其他特殊人群
            },
            riskGrade:0, // 0低风险 1 中风险  2 高风险
            severeSymptomsAssessment:{
                isBreathDifficultyOrShortness:false,
                isPersistentFever: false,
                isBasicDiseasesWorse:false,//基础疾病加重
                isChild:false,
                isPregnant:false,
                isLowSpo2: false,
                isHighRespRate: false,
            },
            isSevereSymptoms:false,
        },
        zliMiniProgram: "#小程序://浙里办/X7Vhjjozslbrc6s",
        zlbDownloadUrl: "http://portal.zjzwfw.gov.cn/download/?_aaid=4&preferredContainer=zlb&goto=zwfw%3A%2F%2Fwebview%3Fh5Url%3Dhttps%3A%2F%2Fmapi.zjzwfw.gov.cn%2Fweb%2Fmgop%2Fgov-open%2Fzj%2F2001501248%2Freserved%2Findex.html",

        //健康湖州发布 公众号APPID
        officialAccount_ZKHZFB : "wx50edf76bc2543f7b",
        //获取微信授权 Code 链接
        wxAuthorizeCodeUrl: "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx50edf76bc2543f7b&redirect_uri=https%3A%2F%2Fcovid19.melodicare.cn&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect",
        antigenTestGuideVideoUrl: "https://mp.weixin.qq.com/s/-Jra9kvWzY8f0qUMNqN21Q",
        //AppID(湖州健康在线小程序ID)
        // wx7254dbeb4e41f326
        //
        // 原始ID
        // gh_b717189b73b0
        //
        // 主页路径
        // /pages/index/index
        miniProgramId_HZJK: "gh_b717189b73b0",
        miniProgramPath_HZJK: "/pages/index/index"


    },


}
