<template>
  <div class="fever-clinic-info">
    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">

      <div class="fever-title">
        为科学应对发热呼吸道传染病，保障患者就医需求，现将湖州市医疗机构发热门诊和基层医疗机构发热诊室名单公布如下。如出现发热等症状，可以拨打咨询电话进行咨询；必要时可在规范佩戴口罩的情况下就近前往诊治。
      </div>

      <div class="fever-query-img-wrapper">
        <img src="../assets/img/fever_clinic_info_search.jpg" alt="">
        <div>长按识别二维码</div>
      </div>


      <div class="fever-hotline-24-container hotline-list">
        <div class="fever-24-title">24 小时开放发热门诊名单</div>
        <div class="hotline-24-item">
          <div class="hospital cell">发热门诊名称</div>
          <div class="address cell">地址</div>
          <div class="phone cell">咨询电话</div>
        </div>
        <div class="hotline-24-item" v-for="(item,index) in hotline24List" :key="item.hospital+index">
          <div class="hospital cell">{{ item.hospital }}</div>
          <div class="address cell">{{item.address}}</div>
          <div class="phone cell">
            <a :href="'tel:'+item.phone" v-if="item.phone">{{item.phone}}</a>
            <div class="cell-child-container" v-else>
              <div class="cell-child-item" v-for="(phoneItem,i) in item.phones" :key="phoneItem.phone+i">
                <div class="cell-child">{{phoneItem.clinicType}}</div>
                <div class="cell-child">{{phoneItem.openTime}}</div>
                <div class="cell-child"><a :href="'tel:'+phoneItem.phone" >{{phoneItem.phone}}</a></div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="basic-hospital-fever-hotline-container hotline-list">
        <div class="fever-24-title">基层医疗机构发热诊室名单</div>
        <div class="hotline-24-item">
          <div class="hospital cell">发热门诊名称</div>
          <div class="address cell">地址</div>
          <div class="open-time cell">开放时间</div>
          <div class="phone cell">咨询电话</div>
        </div>
        <div class="hotline-24-item" v-for="(item,index) in feverHotlineList" :key="item.hospital+index">
          <div class="hospital cell">{{ item.hospital }}</div>
          <div class="address cell">{{item.address}}</div>
          <div class="open-tiem cell">{{item.openTime}}</div>
          <div class="phone cell">
            <a :href="'tel:'+item.phone" v-if="item.phone">{{item.phone}}</a>
            <div class="cell-child-container" v-else>
              <div class="cell-child-item" v-for="(phoneItem,i) in item.phones" :key="phoneItem.phone+i">
                <div class="cell-child">{{phoneItem.clinicType}}</div>
                <div class="cell-child">{{phoneItem.openTime}}</div>
                <div class="cell-child"><a :href="'tel:'+phoneItem.phone" >{{phoneItem.phone}}</a></div>
              </div>
            </div>
          </div>
        </div>

      </div>





    </div>
  </div>
</template>

<script>
import Hotline from "@/views/Hotline";
export default {
  name: "FeverClinicInfo",
  components: {Hotline},
  data() {
    return {
      title:"",
      hotline24List:[
        {
          "hospital": "湖州市中心医院",
          "address": "吴兴区三环北路1558号",
          "phone": "0572-2555252",
          "openTime": "24小时"
        },
        {
          "hospital": "湖州市第一人民医院",
          "address": "吴兴区广场后路158号",
          "phone": "0572-2051000",
          "openTime": "24小时"
        },
        {
          "hospital": "湖州市第三人民医院\n(只限精神专科)",
          "address": "吴兴区苕溪东路2088号",
          "phone": "0572-2062555",
          "openTime": "24小时"
        },
        {
          "hospital": "湖州市妇幼保健院",
          "address": "吴兴区东街2号湖州市妇幼保健院发热门诊",
          "phone": "0572-2030178",
          "openTime": "24小时"
        },
        {
          "hospital": "湖州市中医院",
          "address": "吴兴区南街315号",
          "phone": "0572-2770891",
          "openTime": "24小时"
        },
        {
          "hospital": "吴兴区人民医院",
          "address": "织里镇大港路1599号感染楼",
          "phone": "18367218307",
          "openTime": "24小时"
        },
        {
          "hospital": "吴兴区中西医结合医院",
          "address": "城南路169号(辅助楼一楼)",
          "phone": "0572-2046960",
          "openTime": "24小时"
        },
        {
          "hospital": "湖州交通医院",
          "address": "苕溪东路397号门诊楼一楼",
          "phone": "0572-2064330",
          "openTime": "24小时"
        },
        {
          "hospital": "南浔区人民医院",
          "address": "南浔镇风顺路99号",
          "phone": "0572-3011817",
          "openTime": "24小时"
        },
        {
          "hospital": "南浔区中医院",
          "address": "南浔镇人瑞路166号",
          "phone": "0572-3019282",
          "openTime": "24小时"
        },
        {
          "hospital": "南浔区菱湖人民医院",
          "address": "菱湖镇凤鸣路6666号菱湖人民医院内北侧",
          "phone": "0572-2980140",
          "openTime": "24小时"
        },
        {
          "hospital": "南浔区双林人民医院",
          "address": "双林镇富强路172号",
          "phone": "18768205016",
          "openTime": "24小时"
        },
        {
          "hospital": "南浔区练市人民医院",
          "address": "练市镇湖盐中路131号",
          "phone": "0572-3951565",
          "openTime": "24小时"
        },
        {
          "hospital": "德清县人民医院",
          "address": "德清县武康街道英溪南路120号",
          "phone": "",
          "phones": [
            {
              clinicType:"",
              phone:"0572-8298519",
              openTime:""
            },
            {
              clinicType:"",
              phone:"0572-8298331",
              openTime:""
            },
          ],
          "openTime": "24小时"
        },
        {
          "hospital": "德清县中医院",
          "address": "德清县武康街道群益街383号",
          "phone": "0572-8223710",
          "openTime": "24小时"
        },
        {
          "hospital": "德清县第三人民医院",
          "address": "钱家桥路6号",
          "phone": "0572-8440521",
          "openTime": "24小时"
        },
        {
          "hospital": "长兴县人民医院",
          "address": "龙山街道太湖中路66号",
          "phone": "",
          "phones": [
            {
              clinicType:"成人发热门诊",
              phone:"0572-6267845",
              openTime:""
            },
            {
              clinicType:"儿科发热门诊",
              phone:"0572-6321002",
              openTime:"8:00-22:00"
            },
            {
              clinicType:"儿科发热门诊",
              phone:"0572-6267625",
              openTime:"22:00-次日8:00"
            }
          ],
          "openTime": "24小时"
        },
        {
          "hospital": "长兴县妇幼保健院",
          "address": "龙山街道明珠路861号",
          "phone": "儿科发热门诊\n6265713",
          "openTime": "24小时"
        },
        {
          "hospital": "长兴县中医院",
          "address": "长吕大道99号",
          "phone": "0572-6399768",
          "openTime": "24小时"
        },
        {
          "hospital": "浙江省医疗健康集团长兴医院",
          "address": "煤山镇银杏街",
          "phone": "0572-6295515",
          "openTime": "24小时"
        },
        {
          "hospital": "安吉县人民医院",
          "address": "安吉县天目中路699号",
          "phone": "0572-5031990",
          "openTime": "24小时"
        },
        {
          "hospital": "安吉第二医院",
          "address": "安吉县梅溪镇晓墅青松路",
          "phone": "18768292368",
          "openTime": "24小时"
        },
        {
          "hospital": "安吉县第三人民医院",
          "address": "安吉县孝丰镇苕源路388号",
          "phone": "0572-5058251",
          "openTime": "24小时"
        },
        {
          "hospital": "安吉县中医医院",
          "address": "安吉县递铺镇胜利西路299号",
          "phone": "0572-5230006",
          "openTime": "24小时"
        },
        {
          "hospital": "安吉县妇幼保健院",
          "address": "安吉县云鸿东路18号",
          "phone": "0572-5129088",
          "openTime": "24小时"
        }
      ],

      feverHotlineList:[
        {
          "hospital": "湖州市吴兴区杨家埠街道社区卫生服务中心",
          "address": "杨家埠街道乌陵山路722号",
          "openTime": "8:00-11:00\n13:00-16:00",
          "phone": "0572-2973605"
        },
        {
          "hospital": "湖州市吴兴区龙溪街道社区卫生服务中心",
          "address": "吴兴区赵湾一路222号",
          "openTime": "8:00-11:00\n13:00-16:00",
          "phone": "0572-2350256"
        },
        {
          "hospital": "湖州市吴兴区仁皇山滨湖街道社区卫生服务中心",
          "address": "南皋桥集镇",
          "openTime": "8:00-11:00\n13:00-16:00",
          "phone": "0572-2202647"
        },
        {
          "hospital": "湖州市吴兴区环渚龙泉街道社区卫生服务中心",
          "address": "湖州市高富路251号",
          "openTime": "8:00-11:00\n13:30-16:00",
          "phone": "0572-2370811"
        },
        {
          "hospital": "湖州市吴兴区康山街道社区卫生服务中心",
          "address": "湖州成业路1399号",
          "openTime": "8:00-11:00\n13:00-16:00",
          "phone": "0572-2076737"
        },
        {
          "hospital": "湖州市吴兴区凤凰街道社区卫生服务中心",
          "address": "湖州市凤凰街道黄家庄二路89号",
          "openTime": "8:00-11:00\n13:00-16:00",
          "phone": "0572-2071383"
        },
        {
          "hospital": "湖州市吴兴区朝阳爱山街道社区卫生服务中心",
          "address": "湖州市环城西路231号",
          "openTime": "周一至周六\n8:00-11:00\n13:30-16:00",
          "phone": "0572-2503381"
        },
        {
          "hospital": "湖州市吴兴区月河飞英街道社区卫生服务中心",
          "address": "湖州市东街409号",
          "openTime": "周一至周六\n8:00-11:00\n13:30-16:00",
          "phone": "0572-2353670"
        },
        {
          "hospital": "湖州市吴兴区埭溪镇中心卫生院",
          "address": "吴兴区埭溪镇上强路100号",
          "openTime": "8:00-11:00\n13:00-16:00",
          "phone": "0572-3983029"
        },
        {
          "hospital": "湖州市吴兴区妙西镇卫生院",
          "address": "吴兴区妙西镇茶圣路",
          "openTime": "8:00-11:00\n13:00-16:00",
          "phone": "0572-3122132"
        },
        {
          "hospital": "湖州市吴兴区八里店镇卫生院",
          "address": "吴兴区八里店镇姚山路1号",
          "openTime": "8:00-11:00\n13:00-16:00",
          "phone": "0572-2178683"
        },
        {
          "hospital": "湖州市吴兴区东林镇卫生院",
          "address": "吴兴区东林镇文兴路",
          "openTime": "8:00-11:00\n12:30-16:00",
          "phone": "0572-3330532"
        },
        {
          "hospital": "湖州市吴兴区道场乡卫生院",
          "address": "吴兴区道场乡施家桥集镇",
          "openTime": "8:00-11:00\n13:00-16:00",
          "phone": "0572-3112120"
        },
        {
          "hospital": "湖州市吴兴区织里镇卫生院",
          "address": "吴兴区织里镇洋西栋梁路100号",
          "openTime": "8:00-11:00\n13:30-16:00",
          "phone": "0572-3258795"
        },
        {
          "hospital": "湖州市南浔区和孚镇卫生院",
          "address": "南浔区和孚镇环河路9号",
          "openTime": "24小时",
          "phone": "0572-3961133"
        },
        {
          "hospital": "湖州市南浔区旧馆街道社区卫生服务中心",
          "address": "南浔区旧馆街道同心路139号",
          "openTime": "24小时",
          "phone": "0572-2905419"
        },
        {
          "hospital": "湖州市南浔区善琏镇卫生院",
          "address": "南浔区善琏镇北兴路556号",
          "openTime": "24小时",
          "phone": "0572-3932199"
        },
        {
          "hospital": "湖州市南浔区石淙镇卫生院",
          "address": "南浔区石淙镇河西路1号",
          "openTime": "24小时",
          "phone": "0572-3688235"
        },
        {
          "hospital": "湖州市南浔区千金镇卫生院",
          "address": "南浔区千金镇向阳路44号",
          "openTime": "24小时",
          "phone": "0572-3362120"
        },
        {
          "hospital": "德清县阜溪街道社区卫生服务中心",
          "address": "德清康乾街道虎山路136号",
          "openTime": "上午8:00-11:00\n下午13:00-16:30",
          "phone": "0572-8021014"
        },
        {
          "hospital": "德清县下渚湖街道社区卫生服务中心",
          "address": "德清县下渚湖街道新桥街28号",
          "openTime": "上午8:00-11:00\n下午13:30-16:00",
          "phone": "0572-8481119"
        },
        {
          "hospital": "德清县舞阳街道社区卫生服务中心",
          "address": "德清县舞阳街道上柏正兴路71号",
          "openTime": "上午8:00-11:00\n下午13:00-16:30",
          "phone": "0572-8012939"
        },
        {
          "hospital": "德清县洛舍镇卫生院",
          "address": "德清县洛舍镇复兴南路58号",
          "openTime": "上午8:00-11:00\n下午13:00-16:30",
          "phone": "13957277371"
        },
        {
          "hospital": "德清县新安镇卫生院",
          "address": "德清县新安镇裕华路57号",
          "openTime": "上午8:00-10:30\n下午13:00-16:00",
          "phone": "0572-8461037"
        },
        {
          "hospital": "德清县禹越镇卫生院",
          "address": "德清县禹越镇禹越街51号",
          "openTime": "上午8:00-10:30\n下午13:00-16:00",
          "phone": "0572-8468247"
        },
        {
          "hospital": "德清县雷甸镇卫生院",
          "address": "德清县雷甸镇新大街268号",
          "openTime": "上午8:00-11:00\n下午13:00-16:00",
          "phone": "0572-8485021"
        },
        {
          "hospital": "德清县钟管镇卫生院",
          "address": "德清县钟管镇公园路228号",
          "openTime": "上午8:00-10:30\n下午13:00-16:00",
          "phone": "0572-8401558"
        },
        {
          "hospital": "德清县新市镇中心卫生院",
          "address": "德清县新市镇仙潭路62号",
          "openTime": "上午8:00-11:00\n下午13:00-16:00",
          "phone": "0572-8061030"
        },
        {
          "hospital": "德清县乾元镇中心卫生院",
          "address": "德清县乾元镇医院路80号",
          "openTime": "上午8:00-11:00\n下午13:00-16:30",
          "phone": "0572-8425062"
        },
        {
          "hospital": "德清县莫干山镇卫生院",
          "address": "德清县莫干山镇黄郛东路59号，筏头村488号",
          "openTime": "上午8:00-11:00\n下午13:00-15:30",
          "phone": "0572-8051127"
        },
        {
          "hospital": "德清县武康镇中心卫生院",
          "address": "德清县武康街道体育场街18号",
          "openTime": "周一到周六上午\n8:00-11:30\n周一到周五下午\n13:00-16:30",
          "phone": "0572-8378761"
        },
        {
          "hospital": "长兴县长兴太湖图影旅游度假区社区卫生服务中心",
          "address": "长兴县洪桥镇龙之梦大道1999号",
          "openTime": "24小时",
          "phone": "0572-6660120"
        },
        {
          "hospital": "长兴县画溪街道社区卫生服务中 ",
          "address": "长兴县雉州大道三星斗村徐家村8号",
          "openTime": "24小时",
          "phone": "15157510068"
        },
        {
          "hospital": "长兴县太湖街道社区卫生服务中",
          "address": "长兴县太湖大道1140号",
          "openTime": "24小时",
          "phone": "0572-6678606"
        },
        {
          "hospital": "心长兴县龙山街道社区卫生服务中",
          "address": "长兴县龙山街道玄坛庙路66号",
          "openTime": "24小时",
          "phone": "0572-6298526"
        },
        {
          "hospital": "长兴县雉城街道社区卫生服务中心",
          "address": "雉城街道解放西路900号",
          "openTime": "24小时",
          "phone": "0572-6125332"
        },
        {
          "hospital": "长兴县吕山乡卫生院",
          "address": "长兴县吕山乡雁陶村",
          "openTime": "24小时",
          "phone": "",
          "phones": [
            {
              clinicType:"",
              phone:"0572-6295830",
              openTime:"8:00-21:00"
            },
            {
              clinicType:"",
              phone:"0572-6292802",
              openTime:"21: 00-次日8:00"
            },
          ],
        },
        {
          "hospital": "长兴县和平镇卫生院",
          "address": "长兴县和平镇天能路19号急诊大门西侧",
          "openTime": "24小时",
          "phone": "0572-6519955"
        },
        {
          "hospital": "长兴县洪桥镇卫生院",
          "address": "长兴县洪桥镇洪学路288号",
          "openTime": "24小时",
          "phone": "0572-6064120"
        },
        {
          "hospital": "长兴县林城镇卫生院",
          "address": "长兴县林城镇工业园区",
          "openTime": "24小时",
          "phone": "0572-6294053"
        },
        {
          "hospital": "长兴县泗安镇卫生院",
          "address": "长兴县泗安镇振兴大道666号",
          "openTime": "24小时",
          "phone": "0572-6292120"
        },
        {
          "hospital": "长兴县李家巷镇卫生院",
          "address": "长兴县李家巷镇广福路",
          "openTime": "24小时",
          "phone": "15381288255"
        },
        {
          "hospital": "长兴县夹浦镇卫生院",
          "address": "长兴县夹浦镇夹水路6号",
          "openTime": "24小时",
          "phones": [
            {
              clinicType:"",
              phone:"13735148533",
              openTime:"8:00-16:30"
            },
            {
              clinicType:"",
              phone:"0572-6368975",
              openTime:"16:30-次日8:00"
            },
          ],
        },
        {
          "hospital": "长兴县虹星桥镇卫生院",
          "address": "长兴县虹星桥镇虹星路23号",
          "openTime": "24小时",
          "phone": "15067220320"
        },
        {
          "hospital": "长兴县水口乡卫生院",
          "address": "长兴县水口乡水口村集镇桃园理",
          "openTime": "24小时",
          "phone": "0572-6533198"
        },
        {
          "hospital": "长兴县小浦镇卫生院",
          "address": "长兴县小浦镇浦溪路113号",
          "openTime": "24小时",
          "phone": "0572-6072032"
        },
        {
          "hospital": "兴县煤山镇卫生院",
          "address": "长兴县煤山镇煤青路166号",
          "openTime": "24小时",
          "phone": "0572-6297358"
        },
        {
          "hospital": "安吉天子湖镇中心卫生院",
          "address": "天子湖镇良朋人民路1号",
          "openTime": "8: 00-11: 30\n13:30-16:30",
          "phone": "0572-5690759"
        },
        {
          "hospital": "安吉昌硕街道社区卫生服务中心",
          "address": "昌硕街道桃园路10号",
          "openTime": "8: 00-11: 30\n13:30-16:30",
          "phone": "0572-5311507"
        },
        {
          "hospital": "安吉县递铺街道社区卫生服务中心",
          "address": "递铺街道万亩村",
          "openTime": "8: 00-11: 00\n13:00-16:30",
          "phone": "0572-5114052"
        },
        {
          "hospital": "安吉鄣吴镇卫生院",
          "address": "鄣吴镇鄣吴村下街",
          "openTime": "8: 00-11: 00\n13:00-16:30",
          "phone": "0572-5105099"
        },
        {
          "hospital": "安吉溪龙乡卫生院",
          "address": "溪龙乡溪龙村新区",
          "openTime": "8: 00-11: 00\n13:00-16:00",
          "phone": "0572-5900050"
        },
        {
          "hospital": "安吉山川乡卫生院",
          "address": "山川乡山川村",
          "openTime": "8: 00-11: 00\n12:30-16:00",
          "phone": "0572-5048840"
        },
        {
          "hospital": "安吉梅溪镇中心卫生院",
          "address": "安吉县梅溪镇中街135号",
          "openTime": "8: 00-11: 00\n13:00-16:30",
          "phone": "0572-5081929"
        },
        {
          "hospital": "安吉递铺街道安城社区卫生服务中心",
          "address": "安吉县递铺街道安城东街",
          "openTime": "8: 00-11: 00\n13:00-16:00",
          "phone": "0572-5109120"
        },
        {
          "hospital": "安吉孝源街道社区卫生服务中心",
          "address": "安吉县孝源街道安孝社区职教路66号",
          "openTime": "24小时",
          "phone": "0572-5066586"
        },
        {
          "hospital": "安吉县灵峰街道社区卫生服务中心",
          "address": "安吉县天荒坪镇白水湾村",
          "openTime": "24小时",
          "phone": "13567973097"
        },
        {
          "hospital": "安吉天荒坪镇卫生院",
          "address": "安吉县天荒坪镇马吉村",
          "openTime": "8: 00-11: 00\n13:00-16:30",
          "phone": "0572-5041105"
        },
        {
          "hospital": "安吉上墅乡卫生院",
          "address": "安吉县上墅乡上墅村慢谷大道186号",
          "openTime": "8: 00-11: 00\n13:00-16:30",
          "phone": "0572-5046017"
        },
        {
          "hospital": "安吉杭垓镇中心卫生院",
          "address": "安吉县杭垓镇和谐路199号",
          "openTime": "24小时",
          "phone": "0572-5071419"
        },
        {
          "hospital": "安吉报福镇中心卫生院",
          "address": "安吉县报福镇报福路181号",
          "openTime": "24小时",
          "phone": "0572-5662776"
        },
        {
          "hospital": "安吉孝丰镇卫生院",
          "address": "安吉县孝丰镇迎安南路146148号",
          "openTime": "8: 00-11: 00\n13:00-16:30",
          "phone": "0572-5051228"
        },
        {
          "hospital": "安吉章村镇卫生院",
          "address": "安吉县章村镇复兴路8号",
          "openTime": "8: 00-11: 30\n13:00-16:00",
          "phone": "0572-5078037"
        }
      ]

    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.fever-clinic-info {
  width: 100%;
  .content {
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    background-color: #f6f6f6;
    .fever-title {
      background-color: white;
      border-radius: 10px;
      padding: 10px;
      color: #666666;
      font-size: 14px;
    }
    .fever-query-img-wrapper {
      border-radius: 10px;
      padding: 10px;
      background-color: white;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 300px;
      }
    }
    .fever-hotline-24-container,.hotline-list {
      width: 100%;
      background-color: white;
      border-radius: 10px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      padding: 6px;
      .fever-24-title {
        font-size: 18px;
        color: #1663CD;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .hotline-24-item {
        display: flex;
        flex-direction: row;
        width: 100%;
        .cell {
          width: 0;
          min-width: 0;
          flex: 1;
          border: 1px solid #DDDDDD;
          padding: 4px;
          font-size: 10px;
          display: flex;
          align-items: center;
          word-wrap: break-word;
          word-break: break-all;
          white-space: pre-wrap;
          overflow: hidden;

          .cell-child-container {
            width: 100%;
            .cell-child-item {
              padding: 2px;
              width: 100%;
              display: flex;
              flex-direction: column;
              border-bottom: 1px solid #DDDDDD;
            }
            .cell-child-item:last-child{
              border-bottom: 0;
            }
          }



        }
      }
    }
  }
}

</style>
