<template>
  <div class="referral-reminder">
    如出现持续高热、呼吸困难、指氧饱和度<93%等情况请尽快转诊。
  </div>
</template>

<script>
export default {
  name: "ReferralReminder" //转诊提醒
}
</script>

<style scoped lang="scss">
.referral-reminder {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 20px;
}
</style>