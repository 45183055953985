import { Constant } from "@/store/constant";
const getUID = function(){
  return localStorage.getItem(Constant.UID);
}

function setUID(uid) {
  localStorage.setItem(Constant.UID,uid);
}

function getAccessToken() {
  return localStorage.getItem(Constant.WX_ASSESS_TOKEN)
}
function setAccessToken(accessToken) {
  localStorage.setItem(Constant.WX_ASSESS_TOKEN,accessToken)
}

function getRefreshToken() {
  return localStorage.getItem(Constant.WX_REFRESH_TOKEN)
}
function setRefreshToken(refreshToken) {
  localStorage.setItem(Constant.WX_REFRESH_TOKEN,refreshToken)
}
function getOpenId() {
  return localStorage.getItem(Constant.WX_OPEN_ID)
}
function setOpenId(openId) {
  localStorage.setItem(Constant.WX_OPEN_ID,openId)
}

function setWxNickname(nickname) {
  localStorage.setItem(Constant.WX_NICKNAME,nickname)
}
function getWxNickname() {
  return localStorage.getItem(Constant.WX_NICKNAME)
}



export default {
  getUID,
  setUID,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  getOpenId,
  setOpenId,
  setWxNickname,
  getWxNickname

}
