import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
// @ is an alias to /src
import UserInfoEdit from "@/views/UserInfoEdit";
import RiskGradeAssessment from "@/views/RiskGradeAssessment";
import SevereSymptomsAssessment from "@/views/SevereSymptomsAssessment";
import VitalSignsMonitoring from "@/views/VitalSignsMonitoring"; //生命体征监测
import MedGuide from "@/views/MedGuide"; //用药提醒
import MentalHealthTesting from "@/views/MentalHealthTesting";// 心理健康检测
import CrossInfectionReminder from "@/views/CrossInfectionReminder"; // 交叉感染提醒
import EndIsolationAssessment from "@/views/EndIsolationAssessment"; // 结束隔离评估
import AEDNearby from "@/views/AEDNearby"; // 显示附近 AED
import OnlineHealthConsultation from "@/views/OnlineHealthConsultation"; //在线健康咨询
import GetHealthDevice from "@/views/GetHealthDevice"; // 健康设备获取
import ReferralReminder from "@/views/ReferralReminder"; //转诊提醒
import FollowUpSuggestions from "@/views/FollowUpSuggestions"; //随访建议
import PersonInfoSummary from "@/views/PersonInfoSummary";
import Hotline from "@/views/Hotline";
import NewsHistory from "@/views/NewsHistory";
import VeroColumn from "@/views/VeroColumn";
import FeverClinicInfo from "@/views/FeverClinicInfo";
import Feedback from "@/views/Feedback";


Vue.use(VueRouter)

const routes = [
    //用户信息填写
  {
    path: "/UserInfoEdit",
    name: "UserInfoEdit",
    component: UserInfoEdit,
    meta: {
      title: "基本信息填写"
    }

  },
    //风险等级评级
  {
    path: "/riskGradeAssessment",
    name: "riskGradeAssessment",
    component: RiskGradeAssessment,
    meta: {
      title: "人群分级及标签"
    }
  },
    //主页
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "新冠居家健康管理",
      keepAlive: false,
    }
  },
    //重症评测
  {
    path: "/severeSymptomsAssessment",
    name: "SevereSymptomsAssessment",
    component: SevereSymptomsAssessment,
    meta: {
      title: "重症评测"
    }
  },
    //生命体征监测
  {
    path: "/VitalSignsMonitoring",
    name: "VitalSignsMonitoring",
    component: VitalSignsMonitoring,
    meta: {
      title: "健康参数记录"
    }
  },
    //用药指引
  {
    path: "/MedGuide",
    name: "MedGuide",
    component: MedGuide,
    meta: {
      title: "用药指引"
    }
  },
    //心理健康检测
  {
    path: "/MentalHealthTesting",
    name: "MentalHealthTesting",
    component: MentalHealthTesting,
    meta: {
      title: "心理健康评估"
    }
  },
    //交叉感染提醒
  {
    path: "/CrossInfectionReminder",
    name: "CrossInfectionReminder",
    component: CrossInfectionReminder,
    meta: {
      title: "交叉感染提醒"
    }
  },
    //结束隔离评估
  {
    path: "/EndIsolationAssessment",
    name: "EndIsolationAssessment",
    component: EndIsolationAssessment,
    meta: {
      title: "结束隔离评估"
    }
  },
    //显示附近AED
  {
    path: "/AEDNearby",
    name: "AEDNearby",
    component: AEDNearby,
    meta: {
      title: "紧急呼救"
    }
  },
    //在线健康咨询
  {
    path: "/OnlineHealthConsultation",
    name: "OnlineHealthConsultation",
    component: OnlineHealthConsultation,
    meta: {
      title: "在线健康咨询"
    }
  },
    //健康设备获取
  {
    path: "/GetHealthDevice",
    name: "GetHealthDevice",
    component: GetHealthDevice,
    meta: {
      title: "健康设备获取"
    }
  },
    //转诊提醒
  {
    path: "/ReferralReminder",
    name: "ReferralReminder",
    component: ReferralReminder,
    meta: {
      title: "转诊提醒"
    }
  },
    //随访建议
  {
    path: "/FollowUpSuggestions",
    name: "FollowUpSuggestions",
    component: FollowUpSuggestions,
    meta: {
      title: "发热门诊信息"
    }
  },
  //个人信息汇总
  {
    path: "/PersonInfoSummary",
    name: "PersonInfoSummary",
    component: PersonInfoSummary,
    meta: {
      title: "个人信息汇总"
    }
  },
  //暖心热线
  {
    path: "/Hotline",
    name: "Hotline",
    component: Hotline,
    meta: {
      title: "暖心热线"
    }
  },
  //新闻列表
  {
    path: "/NewsHistory",
    name: "NewsHistory",
    component: NewsHistory,
    meta: {
      title: "最新动态"
    }
  },
  //疫苗专栏
  {
    path: "/VeroColumn",
    name: "VeroColumn",
    component: VeroColumn,
    meta: {
      title: "疫苗专栏"
    }
  },
  //发热门诊信息
  {
    path: "/FeverClinicInfo",
    name: "FeverClinicInfo",
    component: FeverClinicInfo,
    meta: {
      title: "发热门诊信息"
    }
  },
    //意见反馈
  {
    path: "/Feedback",
    name: "Feedback",
    component: Feedback,
    meta: {
      title: "意见反馈"
    }
  },


]

const router = new VueRouter({
  routes
})

export default router
