<template>
  <div class="get-health-device">
    请联系您所在的社区卫生服务中心
  </div>
</template>

<script>
export default {
  name: "GetHealthDevice" //获取健康设备
}
</script>

<style scoped lang="scss">
.get-health-device {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  padding: 20px;
}
</style>