<template>
  <div class="risk-grade-assessment">
    <div class="nav-bar">
      <van-nav-bar
          :left-text="backText"
          :title="title"
          :left-arrow="showLeftArrow"
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <div class="risk-assessment">
        您好！为了更好地为您提供新冠居家健康服务，建议您根据您的实际情况进行选择：
      </div>
      <el-form ref="riskGradeForm" :modal="riskGradeForm" :inline="false" label-position="left">

        <el-form-item label="您的年龄属于">
          <el-radio-group v-model="userInfo.ageRange">
            <el-radio label="<65" >&lt65岁</el-radio>
            <el-radio label="65-79" >65-79岁</el-radio>
            <el-radio label="≥80" >≥80岁</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="疫苗接种情况">
          <el-radio-group v-model="riskGradeForm.vaccination" >
            <el-radio :label="1">全程接种</el-radio>
            <el-radio :label="0">未全程接种</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="基础疾病情况 (基础病如，冠心病、高血压、脑卒中、糖尿病、慢阻肺、慢性肾病、肿瘤、免疫功能缺陷)">
          <p></p>
          <el-radio-group class="rg-basic-disease" v-model="riskGradeForm.basicDiseases" >
            <el-radio :label="0">无基础疾病</el-radio>
            <el-radio :label="1">基础疾病稳定</el-radio>
            <el-radio :label="2">基础疾不稳定</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否为特殊人群">
          <el-radio-group class="special-people" v-model="riskGradeForm.specialPopulationType" >
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">孕妇及哺乳期</el-radio>
            <el-radio :label="2">血透析患者</el-radio>
            <el-radio :label="3">空巢独居老人</el-radio>
            <el-radio :label="4">放化疗患者</el-radio>
            <el-radio :label="5">失能</el-radio>
            <el-radio :label="6">其他特殊人群</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="其他特殊人群备注" v-show="riskGradeForm.specialPopulationType===6">
          <el-input v-model="riskGradeForm.specialPopulationTypeNote" placeholder="请输入其他特殊人群备注"></el-input>
        </el-form-item>

        <el-form-item label="是否已感染新冠">
          <el-radio-group v-model="isCovid19" >
            <el-radio :label="0">未感染</el-radio>
            <el-radio :label="1">已感染</el-radio>
          </el-radio-group>
        </el-form-item>


        <el-form-item size="large">
          <el-button type="primary" @click="onNext">下一步</el-button>
        </el-form-item>


      </el-form>
    </div>

  </div>
</template>

<script>
import {getQuery} from "@/utils/UrlUtils";
import LocalStorage from "@/store/LocalStorage";
export default {
  name: "RiskGradeAssessment",
  data() {
    return {
      title: "",
      backText: "",
      showLeftArrow: false,
      // riskGradeForm: {
      //   vaccination:1, //疫苗接种情况  1 全程接种  2 未全程接种
      //   basicDiseases: 0, //基础疾病情况  0 无基础疾病 1 有基础疾病稳定 2 基础疾病不稳定
      //   specialPopulationType:0, //0 非特殊人群 1 孕妇及哺乳期 2 血透析患者 3 空巢独居老人 4 其他
      //   specialPopulationTypeNote: "", //其他特殊人群
      //   isCovid19: 0, //0未感染 1 已感染
      // }
      userInfo: this.$store.state.userInfo,
      riskGradeForm: this.$store.state.userInfo.riskGradeAssessment,
      isCovid19:this.$store.state.userInfo.isCovid19,
      openId: "",
      uid: "",
      code:"",
      log: "",
      isAccountExist: false,

    }
  },
  watch: {
    // "riskGradeForm.isCovid19":function (newVal,oldVal) {
    //   console.log(newVal,oldVal)
    //   if (newVal === 1) {
    //     this.$router.push("/severeSymptomsAssessment")
    //   }
    // }
  },
  created() {
    this.title = this.$route.meta.title
    this.openId = LocalStorage.getOpenId()
    if (this.$route.query.isAccountExist !== null && this.$route.query.isAccountExist !== undefined) {
      this.isAccountExist = this.$route.query.isAccountExist
    }
    if (this.isAccountExist) {
      this.backText = "返回"
      this.showLeftArrow = true
    }
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onNext() {
      let age = this.userInfo.age;
      let ageRange = this.userInfo.ageRange
      let basicDiseases = this.riskGradeForm.basicDiseases;
      let vaccination = this.riskGradeForm.vaccination
      if ((ageRange !=="≥80" && basicDiseases === 0 ) || (ageRange !=="≥80" && basicDiseases !== 2 && vaccination === 1)) {
        this.userInfo.riskGrade = 0
      }
      if ((ageRange ==="<65" && basicDiseases === 2 && vaccination === 1) || (ageRange ==="65-79" && basicDiseases !== 2 && vaccination === 0) || (ageRange ==="≥80" && basicDiseases!==2 && vaccination ===1)) {
        this.userInfo.riskGrade = 1
      }
      if ((ageRange ==="<65" && basicDiseases === 2 && vaccination === 0) || (ageRange ==="65-79" && basicDiseases === 2 ) || (ageRange ==="≥80" && basicDiseases!==0 && vaccination ===0)) {
        this.userInfo.riskGrade = 2
      }
      this.$store.state.userInfo.isCovid19 = this.isCovid19
      if (this.isAccountExist) {
        this.uid = LocalStorage.getUID()
        if (this.isCovid19 === 1) {
          this.$router.replace("/severeSymptomsAssessment")
        }else {
          // this.updateNucleic(0,0,this.uid)
          this.updateUserInfo(0,0)
          this.$router.replace({
            name: "home",
            params: {
              fromName: this.$route.name
            }
          })
        }

      }else {
        this.createUser()
      }


    },

    updateUserInfo(isPositive,isSevere) {
      let ageRange = this.userInfo.ageRange
      let ageScope  = 0
      if (ageRange === "65-79") {
        ageScope = 1
      }else if (ageRange === "≥80") {
        ageScope = 2
      }else {
        ageScope = 0;
      }
      let basicDiseases = this.riskGradeForm.basicDiseases;
      let vaccination = this.riskGradeForm.vaccination
      // //更新疫苗信息
      // this.updateVero(vaccination,this.uid)
      // //更新风险等级
      // this.updateRiskLevel(this.userInfo.riskGrade,this.uid)
      // this.updateAgeRange(ageRange,this.uid)
      // this.updateCrowd(this.riskGradeForm.specialPopulationType,this.uid)
      // this.updateBasicDisease(basicDiseases,this.uid)
      this.refreshRiskLevel(ageScope,basicDiseases,isPositive,isSevere,
          this.riskGradeForm.specialPopulationType,vaccination,this.userInfo.riskGrade,this.uid)

    },
    refreshRiskLevel(ageScope, disease, isPositive, isSevere, isSpecialCrowd, isVero, riskLevel, uid) {
      this.$net.refreshRiskLevel(ageScope,disease,isPositive,isSevere,isSpecialCrowd,isVero,riskLevel,uid).then((res)=>{
        console.log("refreshRiskLevel",res.data)
      })
    },

    updateVero(isVero, uid) {
      this.$net.updateVero(isVero,uid).then((res)=> {
        console.log("更新疫苗信息",res.data)
      })
    },
    updateRiskLevel(riskLevel, uid) {
      this.$net.updateRiskLevel(riskLevel,uid).then((res)=> {
        console.log("更新风险等级",res.data)
      })
    },
    updateCrowd(isSpecialCrowd, uid) {
      this.$net.updateCrowd(isSpecialCrowd,uid).then((res)=> {
        console.log("更新特殊人群",res.data)
      })
    },
    updateBasicDisease(disease, uid) {
      this.$net.updateBasicDisease(disease,uid).then((res)=> {
        console.log("更新基础疾病",res.data)
      })
    },
    updateAgeRange(ageRange,uid) {
      let ageScope  = 0
      if (ageRange === "65-79") {
        ageScope = 1
      }else if (ageRange === ">=80") {
        ageScope = 2
      }else {
        ageScope = 0;
      }
      this.$net.updateAgeRange(ageScope,uid).then((res)=> {
        console.log("更新年龄范围",res.data)
      })
    },
    updateNucleic(isPositive,isSevere, uid) {
      this.$net.updateNucleic(isPositive,isSevere,uid).then((res)=> {
        console.log("更新新冠病情情况",res.data)
      })
    },


    // getUserInfoByOpenId(openId) {
    //   this.$net.getUserInfoByOpenId(this.openId).then((res)=> {
    //     console.log(res)
    //     if (res.data.status === 200) {
    //       this.isAccountExist = true;
    //       let data = res.data.data
    //       this.uid = data.id
    //       LocalStorage.setUID(this.uid)
    //       this.userInfo.age = data.age
    //       if (data.ageScope === 0) {
    //         this.userInfo.ageRange = "<65"
    //       }else if (data.ageScope === 1) {
    //         this.userInfo.ageRange = "65-80"
    //       }else {
    //         this.userInfo.ageRange = ">80"
    //       }
    //       this.userInfo.covidAntigen = data.isVero
    //       this.userInfo.gender = data.gender
    //       this.userInfo.idNum = data.idNumber
    //       this.userInfo.riskGrade = data.riskLevel
    //       this.userInfo.name = data.nickName
    //       this.isCovid19 = data.isPositive
    //       this.userInfo.isSevereSymptoms = data.isSevere === 1
    //       this.riskGradeForm.specialPopulationType = data.isSpecialCrowd
    //       this.riskGradeForm.basicDiseases = data.isUnderlyingDisease
    //       this.$router.replace("/home")
    //     }else if (res.data.status === 220) { //账户不存在
    //       this.isAccountExist = false
    //       // let accessToken = LocalStorage.getAccessToken()
    //       if (this.accessTokenInfo.access_token) {
    //         this.getWxUserInfo(this.accessTokenInfo.access_token,openId)
    //       }else {
    //         LocalStorage.setOpenId("")
    //         window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx50edf76bc2543f7b&redirect_uri=https%3A%2F%2Fcovid19.melodicare.cn&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
    //       }
    //
    //     }
    //   })
    // },


    createUser() {

      let userAgeScope = 0
      let ageRange = this.userInfo.ageRange
      this.log += "ageRange = "+ageRange
      if (ageRange === "<65") {
        userAgeScope = 0
      }else if (ageRange === "65-80") {
        userAgeScope = 1
      }else if (ageRange === ">80") {
        userAgeScope = 2
      }
      let openid = LocalStorage.getOpenId()
      let userInfoParam = {
        address: "",
        age:0,
        ageScope:userAgeScope,
        emergencyContact: "",
        gender:this.userInfo.gender,
        // idNumber: "",
        isPositive: this.isCovid19,
        isSevere: this.userInfo.isSevereSymptoms? 1: 0,
        isSpecialCrowd: this.riskGradeForm.specialPopulationType,
        isUnderlyingDisease: this.riskGradeForm.basicDiseases,
        isVero: this.riskGradeForm.vaccination,
        nickname: this.userInfo.nickname,
        openId: openid,
        phone: this.userInfo.phoneNum,
        realName: "",
        riskLevel: this.userInfo.riskGrade
      }
      // userInfoParam.isPositive = this.isCovid19
      // userInfoParam.isSevere = this.userInfo.isSevereSymptoms? 1: 0
      // userInfoParam.ageScope = userAgeScope
      // userInfoParam.isSpecialCrowd = this.riskGradeForm.specialPopulationType
      // userInfoParam.isUnderlyingDisease = this.riskGradeForm.basicDiseases
      // userInfoParam.isVero = this.riskGradeForm.vaccination
      // userInfoParam.nickname = this.wxUserInfo.nickname
      // userInfoParam.riskLevel = this.userInfo.riskGrade
      // userInfoParam.openId = this.openId

      this.$net.createUser(userInfoParam).then((res)=> {
        console.log(res)
        this.log += "createUser:"+ JSON.stringify(res.data) +"\n"
        if (res.data.status === 200) { //创建用户成功
          let uid = res.data.data
          this.uid = uid;
          LocalStorage.setUID(uid)
          this.updateUserInfo()
          if (this.isCovid19 === 1) {
            this.$store.state.userInfo.isCovid19 = 1
            this.$router.replace("/severeSymptomsAssessment")
          }else {
            this.updateNucleic(0,0,this.uid)
            this.$router.replace({
              name: "home",
              params: {
                fromName: this.$route.name
              }
            })
          }
        }else {
          this.$message.error(res.data.msg)
        }
      },(err)=> {
        this.$message.error(err)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.risk-grade-assessment {
  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    .risk-assessment {
      margin-bottom: 20px;
    }
    .el-form {
      .el-form-item {

        .rg-basic-disease {
          .el-radio {
            margin-top: 10px;
          }
        }

        .special-people {
          .el-radio {
            margin-top: 20px;
          }
        }
      }
    }
  }


}
</style>
