<template>
  <div class="aed-nearby">
    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <div class="aed-info">
        如需紧急呼救请拨打120。
        如需发送您所在的位置，请您登陆浙里办，搜索“浙里急救”，同时可获取附近的AED设备位置。或直接

        <a :href="zlbDownloadUrl">
          点击前往
        </a>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "AEDNearby",
  data() {
    return {
      title: "",
      //浙里办下载链接
      zlbDownloadUrl: this.$store.state.zlbDownloadUrl
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.aed-nearby {
  .content {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    padding: 20px;

    .aed-info {
      margin-top: 200px;
    }
  }

}
</style>
