<template>
  <div class="home">
    <div class="user-info-container" :class="{'user-info-bg-red':userInfo.riskGrade===2,'user-info-bg-yellow':userInfo.riskGrade===1,'user-info-bg-green':userInfo.riskGrade===0 }">
      <div class="user-name user-info-item">昵称：{{userInfo.nickname}} <span v-if="false" @click="goUserInfoEdit">{{userInfo.name?"修改信息":"完善信息"}}</span></div>
      <div class="user-gender user-info-item" v-if="false">性别：{{userInfo.gender === 1? "男":"女"}}</div>
      <div class="user-gender user-info-item">您的年龄属于：{{userInfo.ageRange}}</div>
      <div class="risk-grade user-info-item" >
        风险等级: <span> {{getRiskType()}} </span>
<!--        <el-button class="re-risk-grade" type="info" size="mini" round @click="goRiskGradeAssessmentView(true)">重新分级</el-button>-->
        <span class="re-risk-grade" @click="goRiskGradeAssessmentView(true)">重新分级</span>
        <i class="el-icon-info" @click="showRiskGradeStandard"/>
      </div>

    </div>
    <div class="is-covid-status">
      <div class="is-covid-19-item user-info-item">
        当前是否感染新冠病毒:<span class="is-covid-19">{{userInfo.isCovid19 === 1 ? "已感染":"未感染"}}</span>
        <van-button class="re-assess" type="info" size="mini" round plain @click="showReAssessIsCovid">重新评估</van-button>
      </div>
      <div class="is-severe-symptoms user-info-item"
           v-show="userInfo.isCovid19 === 1 ">是否重症: <span>{{userInfo.isSevereSymptoms? "重症可能,请就医":"暂无重症指征"}}</span>
        <div class="re-test-wrapper">
          <span class="re-test" @click="goSevereSymptomsAssessment">如您的症状有变化, 请重新评估</span>
<!--          <van-button type="info" size="mini" round plain @click="goSevereSymptomsAssessment">重新评估</van-button>-->
        </div>


      </div>

      <van-dialog v-model="isShowDialog"
                  title="请上传您的最近一次核酸或抗原信息"
                  confirm-button-text="提交"
                  confirm-button-color="#348af9"
                  :closeOnClickOverlay="true"
                  closeOnPopstate
                  show-cancel-button @confirm="handleCovidAssess" @close="">
        <van-field name="nucleicAcid" label="核酸结果">
          <template #input>
            <van-radio-group v-model="userInfo.nucleicAcid" direction="horizontal">
              <van-radio :name="0">阴性</van-radio>
              <van-radio :name="1">阳性</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field name="covidAntigen" label="抗原结果">
          <template #input>
            <van-radio-group v-model="userInfo.covidAntigen" direction="horizontal">
              <van-radio :name="0">阴性</van-radio>
              <van-radio :name="1">阳性</van-radio>
            </van-radio-group>
          </template>
        </van-field>





      </van-dialog>

    </div>

<!--    <el-image-viewer-->
<!--        v-if="showViewer"-->
<!--        :on-close="onImageViewerClose"-->
<!--        :url-list="urlList"-->
<!--    />-->
    <van-dialog class="view-risk-grade" v-model="showViewer" :show-confirm-button="false" closeOnClickOverlay>
      <img class="risk-grade-table" src="../assets/img/risk_grade_table.png" />
    </van-dialog>

    <div class="news-content">
      <van-swipe class="banner-swipe" style="height: 30px;" vertical loop autoplay="10000" :show-indicators="false">
        <van-swipe-item v-for="news in newsList" :key="news.url">
          <div class="news-item" :ref="news.ref" :class="{'news-item-scroll':news.isTextOverflow}">
            <span v-if="news.isNewest">最新动态:</span>
            <a :href="news.url">{{news.title}}</a>
          </div>
        </van-swipe-item>

      </van-swipe>
      <van-button class="re-assess" type="info" size="mini" round plain @click="goNewsHistory" color="indianred">查看全部</van-button>

    </div>


    <div class="function-container">

      <div class="func-item" @click="goVeroColumn">
        <div class="icon-bg">
          <img src="../assets/img/ic_vero.png"/>
        </div>
        <div>疫苗专栏</div>
      </div>

<!--      生命体征监测-->
      <div class="func-item" @click="goVitalSignsMonitoring">
        <div class="icon-bg">
          <img src="../assets/img/1.png"/>
        </div>
        <div>健康参数记录</div>
      </div>
      <div class="func-item" @click="goMedGuide">
        <div class="icon-bg">
          <img src="../assets/img/2.png"/>
        </div>
        <div>用药指引</div>
      </div>
      <div class="func-item" @click="goMentalHealthTesting">
        <div class="icon-bg">
          <img src="../assets/img/3.png"/>
        </div>
        <div>心理健康评估</div>
      </div>
      <div class="func-item" @click="goCrossInfectionReminder">
        <div class="icon-bg">
          <img src="../assets/img/4.png"/>
        </div>
        <div>交叉感染提醒</div>
      </div>
      <div class="func-item" @click="goEndIsolationAssessment">
        <div class="icon-bg">
          <img src="../assets/img/5.png"/>
        </div>
        <div>结束隔离评估</div>
      </div>
      <div class="func-item" @click="goAEDNearby">
        <div class="icon-bg">
          <img src="../assets/img/6.png"/>
        </div>
        <div>紧急呼救</div>
      </div>
      <div class="func-item" @click="goOnlineHealthConsultation">
        <div class="icon-bg">
          <img src="../assets/img/7.png"/>
        </div>
        <div>在线健康咨询</div>
      </div>
      <div class="func-item" @click="goReferralReminder">
        <div class="icon-bg">
          <img src="../assets/img/9.png"/>
        </div>
        <div>重症提醒</div>
      </div>
      <div class="func-item" @click="goFeverClinicInfo">
        <div class="icon-bg">
          <img src="../assets/img/10.png"/>
        </div>
        <div>发热门诊信息</div>
      </div>

      <div class="func-item" @click="goAntigenTestGuide">
        <div class="icon-bg">
          <img src="../assets/img/ic_antigen-test.png"/>
        </div>
        <div>抗原检测教程</div>
      </div>
      <div class="func-item" @click="goHotLine">
        <div class="icon-bg">
          <img src="../assets/img/ic_hotline.png"/>
        </div>
        <div>暖心热线</div>
      </div>


    </div>

    <div class="author">湖州市卫生健康委员会和浙江大学湖州研究院联合开发</div>
    <div class="feedback" @click="goFeedback">意见反馈</div>

  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import OnlineHealthConsultation from "@/views/OnlineHealthConsultation";
import GetHealthDevice from "@/views/GetHealthDevice";
import ReferralReminder from "@/views/ReferralReminder";
import LocalStorage from "@/store/LocalStorage";
import {getQuery} from "@/utils/UrlUtils";
import wx from 'weixin-js-sdk';
export default {
  name: 'HomeView',
  components:{
    ElImageViewer
  },
  data() {
    return {
      // userInfo: {
      //   name: "张三",
      //   gender: 1,
      //   age: 18,
      //   idNum: "",
      //   residentialAddress: "",
      //   phoneNum: "",
      //   emergencyContactName:"",
      //   emergencyContactPhoneNum: "",
      //   isCovid19: 1,
      //   riskGrade: 3,
      //   isSevereSymptoms:1,
      // },

      // covidInfoForm: {
      //   covidAntigen: 0, //抗原 0阴性 1阳性
      //   nucleicAcid: 0,//核酸 0阴性 1阳性
      // },
      userInfo: this.$store.state.userInfo,
      showViewer: false,
      urlList: [
        require("../assets/img/risk_grade_table.png"),
      ],
      newsList: [
        {
          title: "退烧不要仅紧盯布洛芬，看看其他的选择？",
          url: "https://mp.weixin.qq.com/s/FiJApZoMhpn3Nh0VKhcpMw",
          ref: "new_6",
          isTextOverflow: false,
        },
        {
          title: "家里婴幼儿、儿童、孕妇、哺乳期妈妈“阳”了，如何用药？",
          url: "https://mp.weixin.qq.com/s/iN3uFESTEOjg-xTjgsOU9w",
          ref: "new_5",
          isTextOverflow: false,
        },
        {
          title: "感染后“刀片嗓”如何快速缓解？专家解答！",
          url: "https://mp.weixin.qq.com/s/FlHDtxHmKSuH2OC9Z4D-YA",
          ref: "new_4",
          isTextOverflow: false,
        },

        {
          title: "新冠感染后的7天，我是这样度过的",
          url: "https://mp.weixin.qq.com/s/UfJtSis13JZwAXxB0UxUXw",
          ref: "new_3",
          isTextOverflow: false,
        },
        {
          title:"湖州市新冠病毒感染者居家中医药健康管理专家指南",
          url: "https://mp.weixin.qq.com/s/bDwJU-hI5QcUoSAzREgK2w",
          ref:"news_1",
          isTextOverflow: false,
        },
        {
          title: "第二剂次加强疫苗来了！这些人群可接种",
          url: "https://mp.weixin.qq.com/s/Csa8i8XJ1PYwi7O_TQwYzA",
          ref:"news_2",
          isTextOverflow: false,
        },
      ],
      newsStyle: {

      },
      openId: "",
      wxAuthorizeCode: "",
      accessTokenInfo: {
        access_token: "",
        expire_in: 7200,
        refresh_token: "",
        openid: "",
        scope: "",
      },
      wxUserInfo: {
        openid: "",
        nickname: "",
        sex: 0,
        language: "",
        city: "",
        province: "",
        country: "",
        headimgurl: "",
        privilage: ""
      },
      isShowDialog: false,
    }
  },
  created() {
    let from = this.$route.params.fromName
    console.log(from)
    if (from!== null && from !== undefined && (from === "riskGradeAssessment" || from === "SevereSymptomsAssessment" )) {
      console.log(this.$store.state.userInfo)
    }else {
      // this.openId = "oHy8pwdG2dh3RW0r2KuxiOQwc4jg"; //本地测试用
      this.openId = LocalStorage.getOpenId() //正式打包用
      this.accessTokenInfo.access_token = LocalStorage.getAccessToken()
      if (this.openId !== null && this.openId !== undefined && this.openId !== "") {
        this.getUserInfoByOpenId()
        // this.getWxConfig()
      }else {
        this.wxAuthorizeCode = getQuery(window.location.href).code
        //通过微信重定向的 url 获取 code
        if (!this.wxAuthorizeCode) {
          this.requestWxAuthorizeCode()
        }else {
          //已有 code 获取access token
          this.getAssessToken(this.wxAuthorizeCode)
        }
      }
    }


  },

  mounted() {
    if (this.openId !== null && this.openId !== undefined && this.openId !== "") {
      this.getWxConfig()
    }


    for (let i = 0; i < this.newsList.length; i++) {
      // console.log(this.newsList[i])
      let isOver  = this.isOverFlow(this.newsList[i].ref)
      this.newsList[i].isTextOverflow = isOver
      this.$set(this.newsList,i,this.newsList[i] )
    }

  },


  methods: {

    async getWxConfig() {
      let url = window.location.href.split("#")[0]
      let {data} = await this.$net.generateWxSign(url)
      if ("signature" in data) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印
          appId: this.$store.state.officialAccount_ZKHZFB, // 必填，公众号的唯一标识
          timestamp: data.timestamp, // 必填，生成签名的时间戳
          nonceStr: data.nonceStr, // 必填，生成签名的随机串
          signature: data.signature,// 必填，签名
          jsApiList: ['updateAppMessageShareData','updateTimelineShareData','wx-open-launch-weapp','chooseImage', 'previewImage'],
          openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-weapp']
        })
        wx.ready(()=> {
          //config信息验证后会执行 ready 方法，所有接口调用都必须在 config 接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在 ready 函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在 ready 函数中。
          console.log("wx.ready")
          wx.updateAppMessageShareData({
            title: '新冠居家健康管理', // 分享标题
            desc: '湖州市卫健委，浙江大学湖州研究院', // 分享描述
            link: 'https://covid19.melodicare.cn', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: '', // 分享图标
            success: function () {
              // 设置成功
            }
          })
          wx.updateTimelineShareData({
            title: '新冠居家健康管理', // 分享标题
            // desc: '湖州市卫健委，浙江大学湖州研究院', // 分享描述
            link: 'https://covid19.melodicare.cn', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: '', // 分享图标
            success: function () {
              // 设置成功
            }
          })
          document.addEventListener('WeixinOpenTagsError', function (e) {
            // console.error(e.detail.errMsg); // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
            this.$toast.fail(e.detail.errMsg)
          });

        })
        wx.error(function(res){
          // config信息验证失败会执行 error 函数，如签名过期导致验证失败，具体错误信息可以打开 config 的debug模式查看，也可以在返回的 res 参数中查看，对于 SPA 可以在这里更新签名。
        });

      }
    },


    getRiskType() {
      if (this.userInfo.riskGrade ===0) {
        return "低风险一般人群"
      }else if (this.userInfo.riskGrade === 1) {
        return "中风险次重点人群"
      }else {
        return "高风险重点人群"
      }
    },
    showRiskGradeStandard() {
      this.showViewer = true
    },
    onImageViewerClose() {
      this.showViewer = false
    },
    goVitalSignsMonitoring(){
      this.$router.push("/VitalSignsMonitoring")
    },
    goMedGuide(){
      this.$router.push("/MedGuide")
    },
    goMentalHealthTesting(){
      this.$router.push("/MentalHealthTesting")
    },
    goCrossInfectionReminder(){
      this.$router.push("/CrossInfectionReminder")
    },
    goEndIsolationAssessment(){
      this.$router.push("/EndIsolationAssessment")
    },
    goAEDNearby(){
      this.$router.push("/AEDNearby")
    },
    goOnlineHealthConsultation(){
      this.$router.push("/OnlineHealthConsultation")
    },
    goVeroColumn(){
      this.$router.push({
        name: "VeroColumn"
      })
    },
    goReferralReminder(){
      this.$router.push("/severeSymptomsAssessment")
    },
    goFeverClinicInfo(){
      // this.$router.push("/FollowUpSuggestions")
      // window.open("https://mp.weixin.qq.com/s/FiTytJpqaxavBkW4OVp7WA")
      this.$router.push({
        name: "FeverClinicInfo",
      })
    },
    goSevereSymptomsAssessment() {
      this.$router.push({
        path: "/severeSymptomsAssessment",
        query: {
          redirect:this.$route.fullPath
        }
      })
    },
    goUserInfoEdit() {
      this.$router.push("/UserInfoEdit")
    },
    goAntigenTestGuide() {
      // window.open("https://mp.weixin.qq.com/s/dLzD5xkh_a9V5G6F0l1Hnw","_blank")
      window.open(this.$store.state.antigenTestGuideVideoUrl)
      // window.open("https://mp.weixin.qq.com/s/QABXeSUz06Xc7P5sZOaFhQ")
      // window.location.href = "https://weixin.qq.com/sph/AOofGO"
    },
    goHotLine() {
      this.$router.push("/Hotline")
    },
    goNews() {
      window.open("https://mp.weixin.qq.com/s/Csa8i8XJ1PYwi7O_TQwYzA")
    },

    /**
     * 通过 openId 获取用户信息是否已存在 已存在同步信息，不存在跳转到评级页面创建页面
     * @param openId
     */
    getUserInfoByOpenId() {
      this.$net.getUserInfoByOpenId(this.openId).then((res)=> {
        console.log(res.data)
        if (res.data.status === 200) { //用户已存在 同步信息
          let data = res.data.data
          this.uid = data.id
          LocalStorage.setUID(this.uid)
          // LocalStorage.setOpenId(this.openId)
          this.userInfo.age = data.age
          this.userInfo.ageScope = data.ageScope
          if (data.ageScope === 0) {
            this.userInfo.ageRange = "<65"
          }else if (data.ageScope === 1) {
            this.userInfo.ageRange = "65-79"
          }else {
            this.userInfo.ageRange = "≥80"
          }
          this.userInfo.riskGradeAssessment.vaccination = data.isVero
          this.userInfo.gender = data.gender
          this.userInfo.idNum = data.idNumber
          this.userInfo.riskGrade = data.riskLevel
          this.userInfo.nickname = data.nickname
          this.userInfo.isCovid19 = data.isPositive
          this.userInfo.isSevereSymptoms = data.isSevere === 1
          this.userInfo.riskGradeAssessment.specialPopulationType = data.isSpecialCrowd
          this.userInfo.riskGradeAssessment.basicDiseases = data.isUnderlyingDisease
        }else if (res.data.status === 220) { //账户不存在 ,跳转评级页面生成用户
          if (this.wxUserInfo.openid === "") {
            this.openId = ""
            LocalStorage.setOpenId("")
            LocalStorage.setUID("")
            this.requestWxAuthorizeCode()
          }else {
            this.$router.replace("/riskGradeAssessment")
          }

        }
      })
    },
    /**
     * 账户不存在 获取微信用户信息
     */
    handleAccountNotExist() {
      this.requestWxAuthorizeCode()
    },

    requestWxAuthorizeCode(){
      window.location.href = this.$store.state.wxAuthorizeCodeUrl
    },

    /**
     * 获取微信 AccessToken 和 openid
     * @param code
     */
    getAssessToken(code) {
      this.$net.getAssessToken(code).then((res)=>{
        let data = res.data
        console.log(res)
        if ("access_token" in data) {
          this.accessTokenInfo = res.data
          //成功拿到 openid 从微信服务器拉取用户信息
          this.openId = this.accessTokenInfo.openid
          LocalStorage.setOpenId(this.openId)
          LocalStorage.setAccessToken(this.accessTokenInfo.access_token)
          LocalStorage.setRefreshToken(this.accessTokenInfo.refresh_token)
          this.getWxUserInfo(this.accessTokenInfo.access_token,this.openId)
        }else if ("errcode" in data) {
          LocalStorage.setOpenId("")
          this.requestWxAuthorizeCode()
        }

      })
    },
    getWxUserInfo(accessToken, openId) {
      this.$net.getWxUserInfo(accessToken,openId).then((res)=>{
        console.log(res)
        this.log += "getWxUserInfo:"+ JSON.stringify(res.data) +"\n"
        let data = res.data
        if ("openid" in data) {
          this.wxUserInfo = data
          this.userInfo.nickname = this.wxUserInfo.nickname
          this.$store.state.userInfo.nickname = this.wxUserInfo.nickname
          LocalStorage.setWxNickname(this.wxUserInfo.nickname)
          let gender = 1
          if (this.wxUserInfo.sex === 2){
            gender = 0
          }
          this.userInfo.gender = gender
          //获取微信用户信息成功，从自己服务器获取用户是否存在
          this.getUserInfoByOpenId()

        }else if ("errcode" in data) {

        }else {
        }
      },(err)=> {
      })
    },

    /**
     * 是否是重新编辑
     * @param isReEdit
     */
    goRiskGradeAssessmentView(isReEdit) {
      if(isReEdit) {
        this.$router.push({
          path: "/riskGradeAssessment",
          query: {
            isAccountExist: true
          }
        })
      }else {
        this.$router.replace("/riskGradeAssessment")
      }
    },
    isOverFlow(ref) {
      let el = this.$refs[ref][0]
      // console.log("c",el.clientWidth)
      // console.log("s",el.scrollWidth)
      return el.scrollWidth > el.clientWidth
    },
    //显示重新评估是否感染新冠
    showReAssessIsCovid() {
      this.isShowDialog = true
    },
    closeReAssessIsCovid() {
      this.isShowDialog = false
    },
    handleCovidAssess() {
      if (this.userInfo.nucleicAcid === 1 || this.userInfo.covidAntigen === 1) {
        this.$store.state.userInfo.isCovid19 = 1
        this.$router.push({
          name:"SevereSymptomsAssessment"
        })
      }else {
        this.userInfo.isCovid19 = 0
        this.$net.updateNucleic(0,0,this.uid).then((res)=> {
          console.log("updateNucleic",res)
        })
      }
    },
    goNewsHistory() {
      this.$router.push({
        name: "NewsHistory"
      })
    },
    goFeedback() {
      this.$router.push({
        name: "Feedback"
      })
    },

  }

}
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6;

  .user-info-container {
    width: 95%;
    margin-top: 10px;
    border-radius: 20px;
    padding: 10px;
    color: white;
    box-sizing: border-box;

    .user-name {
      display: flex;
      justify-content: space-between;
    }

    .user-info-item {
      margin-top: 10px;
    }

    .user-info-item:first-child {
      margin-top: 5px;
    }

    .risk-grade {
      margin: 10px 0;

      .re-risk-grade {
        margin-left: 20px;
        margin-right: 10px;
        border: white solid 1px;
        border-radius: 20px;
        padding: 2px 5px;
      }

    }

  }
  .user-info-bg-red{
    background-color: #ED1521;
  }
  .user-info-bg-yellow {
    background-color: #EDBE15;
  }
  .user-info-bg-green {
    background-color: #55BF3D;
  }
  .is-covid-status {
    width: 95%;
    padding: 20px 10px 20px 10px;
    margin-top: 20px;
    border-radius: 20px;
    background-color: white;
    box-sizing: border-box;
    .is-covid-19-item {
      display: flex;
      align-items: center;
      .is-covid-19 {
        margin-left: 10px;
      }
      .re-assess {
        margin-left: 10px;
      }
      //span {
      //  display: inline-block;
      //  background-color: white;
      //  color: red;
      //}
    }
    .is-severe-symptoms {
      margin-top: 10px;
      span{
        display: inline-block;
        margin-left: 2px;
      }
      .re-test-wrapper {
        display: flex;
        align-items: center;
        margin-top: 4px;
        .re-test {
          display: inline-block;
          color: #348af9;
          margin-right: 10px;
        }

      }

    }
  }

  @keyframes wordsLoop {
    0% {
      transform: translateX(0px);
    }
    25% {
      transform: translateX(0px);
    }
    75% {
      transform: translateX(calc(-100% + 10rem));
    }
    100% {
      transform: translateX(calc(-100% + 10rem));
    }
  }
  @-webkit-keyframes wordsLoop {
    0% {
      transform: translateX(0px);
    }
    25% {
      transform: translateX(0px);
    }
    75% {
      transform: translateX(calc(-100% + 10rem));
    }
    100% {
      transform: translateX(calc(-100% + 10rem));
    }
  }

  .view-risk-grade {
    width: 360px;
    .risk-grade-table {
      width: 360px;
      object-fit: contain;
    }
  }

  .news-content {
    width: 95%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 20px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .banner-swipe {
      flex: 1;
      margin-right: 10px;
      .van-swipe-item {
        display: flex;
        align-items: center;
        .news-item {
          width: 100%;
          white-space: nowrap;
          span {
            color: #ED1521;
          }
          background-color: white;
          a{
            color: indianred;
          }
        }
        .news-item-scroll {
          animation: 10s wordsLoop linear infinite;
        }
      }
    }



  }

  .function-container {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    margin-top: 20px;
    border-radius: 20px;
    padding-bottom: 10px;
    .func-item {
      width: 33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      img {
        display: inline-block;
        width: 50px;
        height: 50px;
        margin: 5px;
        font-size: 50px;
        color: white;

      }
    }


  }

  .author {
    margin: 20px;
    color: #409eff;
    text-align: center;
    font-size: 12px;
  }
  .feedback {
    font-size: 12px;
    color: #666666;
    text-align: center;
    padding-bottom: 40px;
  }

}
</style>
