<template>
  <div class="severeSymptomsAssessment">
    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <div class="severe-assessment-title">
        请选择您是否出现下述症状或情况
      </div>
      <el-form v-if="false" ref="severeSymptomsForm" label-position="left" label-width="100%" :inline="true">
        <el-form-item label="1. 呼吸困难或急促">
          <el-radio-group v-model="severeSymptomsAssessment.isBreathDifficultyOrShortness">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="2. 经药物治疗后体温仍持续高于38.5℃，超过3天">
          <el-radio-group v-model="severeSymptomsAssessment.isPersistentFever">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="3. 原有基础疾病明显加重且不能控制">
          <el-radio-group v-model="severeSymptomsAssessment.isBasicDiseasesWorse">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="4. 儿童出现嗜睡、持续拒食、喂养困难、持续腹泻或呕吐等情况">
          <el-radio-group v-model="severeSymptomsAssessment.isChild">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="5. 孕妇出现头痛、头晕、心慌、憋气等症状，或出现腹痛、阴道出血或流液、胎动异常等情况">
          <el-radio-group v-model="severeSymptomsAssessment.isPregnant">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="6. 是否血氧饱和度低于94%">
          <el-radio-group v-model="severeSymptomsAssessment.isLowSpo2">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="7. 呼吸率高于 28次/分">
          <el-radio-group v-model="severeSymptomsAssessment.isHighRespRate">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>


        <el-form-item >
          <el-button type="primary" @click="onNext">下一步</el-button>
        </el-form-item>

      </el-form>



      <van-form>

        <van-field label="1. 呼吸困难或急促">
          <template #input>
            <van-radio-group v-model="severeSymptomsAssessment.isBreathDifficultyOrShortness" direction="horizontal">
              <van-radio :name="true">是</van-radio>
              <van-radio :name="false">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field label="2. 经药物治疗后体温仍持续高于38.5℃，超过3天">
          <template #input>
            <van-radio-group v-model="severeSymptomsAssessment.isPersistentFever" direction="horizontal">
              <van-radio :name="true">是</van-radio>
              <van-radio :name="false">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field label="3. 原有基础疾病明显加重且不能控制">
          <template #input>
            <van-radio-group v-model="severeSymptomsAssessment.isBasicDiseasesWorse" direction="horizontal">
              <van-radio :name="true">是</van-radio>
              <van-radio :name="false">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field label="4. 儿童出现嗜睡、持续拒食、喂养困难、持续腹泻或呕吐等情况">
          <template #input>
            <van-radio-group v-model="severeSymptomsAssessment.isChild" direction="horizontal">
              <van-radio :name="true">是</van-radio>
              <van-radio :name="false">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field label="5. 孕妇出现头痛、头晕、心慌、憋气等症状，或出现腹痛、阴道出血或流液、胎动异常等情况">
          <template #input>
            <van-radio-group v-model="severeSymptomsAssessment.isPregnant" direction="horizontal">
              <van-radio :name="true">是</van-radio>
              <van-radio :name="false">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field label="6. 是否血氧饱和度低于94%">
          <template #input>
            <van-radio-group v-model="severeSymptomsAssessment.isLowSpo2" direction="horizontal">
              <van-radio :name="true">是</van-radio>
              <van-radio :name="false">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>

        <van-field label="7. 呼吸率高于 28次/分">
          <template #input>
            <van-radio-group v-model="severeSymptomsAssessment.isHighRespRate" direction="horizontal">
              <van-radio :name="true">是</van-radio>
              <van-radio :name="false">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>


        <div style="margin: 16px;">
          <van-button round block type="info"  @click="onNext">提交</van-button>
        </div>



      </van-form>



      <el-dialog
          class="alert-dialog"
          title="提示"
          :show-close="false"
          :visible.sync="isShowAlert"
          width="80%"
          center @close="onAlertDialogClose">
        <div slot="title" class="dialog-title">
          重症报警
        </div>
        <span class="alert-content">如您确认出现上述症状，可通过自驾车、120救护车等方式，转至相关医院进行治疗</span>
        <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="isShowAlert = false">确 定</el-button>
      </span>
      </el-dialog>
    </div>



  </div>
</template>

<script>
import LocalStorage from "@/store/LocalStorage";

export default {
  name: "SevereSymptomsAssessment",
  data() {
    return {
      title: "",
      // severeSymptomsAssessment:{
      //   isBreathDifficultyOrShortness:false,
      //   isPersistentFever: false,
      //   isBasicDiseasesWorse:false,//基础疾病加重
      //   isChild:false,
      //   isPregnant:false,
      // }
      severeSymptomsAssessment: this.$store.state.userInfo.severeSymptomsAssessment,
      isShowAlert: false,
      uid:"",
    }
  },
  created() {
    this.title = this.$route.meta.title
    this.uid = LocalStorage.getUID()
  },
  methods: {
    onNext() {
      this.$store.state.userInfo.isSevereSymptoms = false;
      for (let key in this.severeSymptomsAssessment) {
        // console.log(key,this.severeSymptomsAssessment[key])
        // this.$store.state.userInfo.severeSymptomsAssessment[key] = this.severeSymptomsAssessment[key]
        if (this.severeSymptomsAssessment[key]) {
          this.$store.state.userInfo.isSevereSymptoms = true;
          break
        }
      }
      // this.$store.state.userInfo.isCovid19 = 1
      let isCovid19 = this.$store.state.userInfo.isCovid19
      if (this.$store.state.userInfo.isSevereSymptoms) {
        // this.updateNucleic(1,1,this.uid)
        this.updateUserInfo(isCovid19,1)
        this.showAlertDialog()
      } else {
        // this.updateNucleic(1,0,this.uid)
        this.updateUserInfo(isCovid19,0)
        this.$alert("根据您的自我评估结果，建议居家隔离进行健康康复，如症状变化请您及时就医","健康提醒",{
          confirmButtonText: "确定",
          showClose:false,
          callback: action => {
            this.$router.replace({
              name: "home",
              params: {
                fromName: this.$route.name
              }
            })
          }
        })

      }

    },
    updateUserInfo(isPositive,isSevere) {
      let ageRange = this.$store.state.userInfo.ageRange
      let ageScope  = 0
      if (ageRange === "65-79") {
        ageScope = 1
      }else if (ageRange === ">=80") {
        ageScope = 2
      }else {
        ageScope = 0;
      }
      let basicDiseases = this.$store.state.userInfo.riskGradeAssessment.basicDiseases;
      let vaccination = this.$store.state.userInfo.riskGradeAssessment.vaccination
      // //更新疫苗信息
      // this.updateVero(vaccination,this.uid)
      // //更新风险等级
      // this.updateRiskLevel(this.userInfo.riskGrade,this.uid)
      // this.updateAgeRange(ageRange,this.uid)
      // this.updateCrowd(this.riskGradeForm.specialPopulationType,this.uid)
      // this.updateBasicDisease(basicDiseases,this.uid)
      this.refreshRiskLevel(ageScope,basicDiseases,isPositive,isSevere,
          this.$store.state.userInfo.riskGradeAssessment.specialPopulationType,vaccination,this.$store.state.userInfo.riskGrade,this.uid)

    },
    refreshRiskLevel(ageScope, disease, isPositive, isSevere, isSpecialCrowd, isVero, riskLevel, uid) {
      this.$net.refreshRiskLevel(ageScope,disease,isPositive,isSevere,isSpecialCrowd,isVero,riskLevel,uid).then((res)=>{
        console.log("refreshRiskLevel",res.data)
      })
    },
    showAlertDialog() {
      // this.$alert("如您确认出现上述症状，可通过自驾车、120救护车等方式，转至相关医院进行治疗","重症提醒",{
      //   confirmButtonText: "确定",
      //   showClose:false,
      //   callback: action => {
      //     this.$router.push("/home")
      //   }
      // })
      this.isShowAlert = true
    },
    onAlertDialogClose() {
      // console.log(this.$route)
      // this.$router.replace("/home")
      let from = this.$route.query.from
      console.log(from)
      if (from === "/") {
        this.$router.back()
      }else if (from === "/VitalSignsMonitoring"){
        this.$router.back()
      }else {
        this.$router.replace({
          name: "home",
          params: {
            fromName: this.$route.name
          }
        })
      }

    },
    updateNucleic(isPositive,isSevere, uid) {
      this.$net.updateNucleic(isPositive,isSevere,uid).then((res)=> {
        console.log("更新新冠病情情况",res.data)
      })
    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.severeSymptomsAssessment {
  height: 100%;
  .content {
    height: 100%;
    overflow-y: scroll;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .severe-assessment-title {
      margin-bottom: 20px;
    }
    .van-cell:first-child {
    }
    .van-cell {
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      :deep(.van-cell__title) {
        width: 100%;
        margin-bottom: 8px;
      }
      .van-radio--horizontal {
        margin-right: 30px;
      }
    }

    .alert-dialog {
      border-radius: 10px;
      :deep(.el-dialog__header) {
        padding: 0 !important;
      }
      :deep(.el-dialog__footer) {
        padding: 0 !important;
      }
      :deep(.el-dialog) {
        border-radius: 20px;
        overflow: hidden;
      }
      .dialog-title {
        background-color: #ED1521;
        color: white;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        padding: 10px;
      }
      .alert-content {
        font-size: 20px;
      }
      .dialog-footer {
        .el-button {
          width: 50%;
          margin-bottom: 10px;
          background-color: #ED1521;
          border: 0;
        }
      }
    }
  }

}

</style>
