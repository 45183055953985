<template>
  <div class="cross-infection-reminder">
    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <div class="reminder-img-wrapper">
        <el-image v-for="item in reminderImgs" :src="item"></el-image>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CrossInfectionReminder", //交叉感染提醒
  data() {
    return {
      title:"",
      reminderImgs:[
          require("../assets/img/reminder_1.jpg"),
        require("../assets/img/reminder_2.jpg"),
        require("../assets/img/reminder_3.jpg"),
      ]
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">

</style>
