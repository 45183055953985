<template>
  <div class="user-info">
    <el-form ref="userInfoForm" :modle="userInfoForm" label-width="auto" :inline="false">
      <el-form-item label="昵称">
        <el-input v-model="userInfoForm.name"></el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="userInfoForm.gender" >
          <el-radio :label="1">男</el-radio>
          <el-radio :label="0">女</el-radio>
        </el-radio-group>
      </el-form-item>

<!--      <el-form-item label="年龄">-->
<!--        <el-input type="number" step="1" v-model="userInfoForm.age"></el-input>-->
<!--      </el-form-item>-->

      <el-form-item label="身份证号">
        <el-input v-model="userInfoForm.idNum"></el-input>
      </el-form-item>

      <el-form-item label="现居住地址">
        <el-input v-model="userInfoForm.residentialAddress"></el-input>
      </el-form-item>

      <el-form-item label="联系电话">
        <el-input v-model="userInfoForm.phoneNum"></el-input>
      </el-form-item>

      <el-form-item label="紧急联系人姓名">
        <el-input v-model="userInfoForm.emergencyContactName"></el-input>
      </el-form-item>

      <el-form-item label="紧急联系人电话">
        <el-input v-model="userInfoForm.emergencyContactPhoneNum"></el-input>
      </el-form-item>

      <el-form-item size="large">
        <el-button type="primary" @click="onNext">确定</el-button>
      </el-form-item>


    </el-form>


  </div>
</template>

<script>
export default {
  name: "UserInfoEdit",
  data() {
    return{
      // userInfoForm: {
      //   name: "",
      //   gender: 1,
      //   age: 0,
      //   idNum: "",
      //   residentialAddress: "",
      //   phoneNum: "",
      //   emergencyContactName:"",
      //   emergencyContactPhoneNum: "",
      // }
      userInfoForm: this.$store.state.userInfo

    }
  },
  methods: {
    onNext() {
      this.$router.back()
    },
  }
}
</script>

<style scoped lang="scss">
.user-info {
  padding: 20px;
  color: black;
}
</style>
