<template>
  <div class="end-isolation-assessment">
    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <div class="end-isolation-title">
        建议您根据实际情况填写您的个人情况。
      </div>
      <div class="assessment-content">
        <el-form ref="endIsolationForm" label-width="100%" :inline="true" label-position="left">
          <el-form-item label="是否有发热症状">
            <el-radio-group v-model="isFever" >
              <el-radio :label="false">无</el-radio>
              <el-radio :label="true">有</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="体温" label-width="40px" v-show="isFever">
            <el-input type="number" v-model="bodyTemp">
              <template slot="append">℃</template>
            </el-input>
          </el-form-item>

          <el-form-item label="您是否具有 咽痛、咳嗽、咳痰、气急、嗜睡、流涕喷嚏、鼻塞、恶心呕吐症状">
            <el-radio-group v-model="isHasOtherSymptom" >
              <el-radio :label="false">无</el-radio>
              <el-radio :label="true">有</el-radio>
            </el-radio-group>
          </el-form-item>


          <el-form-item label="您是否符合自测抗原阴性并且连续两次新冠病毒核酸检测Ct值≥35（两次检测间隔大于24小时）">
            <el-radio-group v-model="covidAntigenAndNucleicAcid">
              <el-radio :label="0">符合</el-radio>
              <el-radio :label="1">不符合</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="onSubmit" class="bt-submit">评估</el-button>
          </el-form-item>

        </el-form>
        <div class="assessment-item">
        </div>
      </div>
    </div>



  </div>
</template>

<script>
export default {
  name: "EndIsolationAssessment", //结束隔离评估
  data() {
    return {
      title: "",
      isFever: false,
      isHasOtherSymptom: false,
      bodyTemp: "",
      covidAntigenAndNucleicAcid: 0, //抗原及核酸情况  0 符合 1  不符合
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    onSubmit() {
      if (!this.isFever && !this.isHasOtherSymptom && this.covidAntigenAndNucleicAcid === 0) {
        this.$alert("可结束居家治疗，恢复正常生活和外出","结束隔离评估结果",{
          showClose: false,
          callback: action => {
            this.$router.back()
          }
        })
      }else {
        this.$alert("不可结束居家治疗","结束隔离评估结果",{
          showClose: false,
          callback: action => {
            this.$router.back()
          }
        })
      }
    },
    onClickLeft() {
      this.$router.back()
    },
  }
}
</script>

<style scoped lang="scss">
.end-isolation-assessment {
  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    .end-isolation-title {
      margin-bottom: 20px;
    }
    .assessment-content {
      .el-form {
        .bt-submit {
          //width: 100%;
        }
      }
    }
  }


}
</style>
