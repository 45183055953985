<template>
  <div class="person-info-summary">

    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <el-empty class="empty-view" description="暂无历史记录" v-if="measureInfoList.length===0">
        <el-button type="primary" @click="goSubmit">立即上传</el-button>
      </el-empty>

      <div class="measure-history-list" v-else>
        <div class="measure-history-item" v-for="measureItemInfo in measureInfoList" :key="measureItemInfo.id">
          <div class="item-title">
            <div class="measure-time">{{measureItemInfo.year}}年{{measureItemInfo.month}}月{{measureItemInfo.day}}日</div>
          </div>
          <div class="item-content">
            <div class="antigen" >
              抗原: <span :class="measureItemInfo.antigenResult ===0? 'antigen-green': 'antigen-red'">
          {{measureItemInfo.antigenResult ===0?"阴性":"阳性"}}
        </span>
            </div>
            <div class="body-temp">体温: {{measureItemInfo.bodyTemp}} ℃</div>
            <div class="heart-rate">心率: {{measureItemInfo.heartRate}} bpm</div>
            <div class="nucleic-acid">
              核酸: <span :class="measureItemInfo.nucleicAcidResult ===1? 'nucleic-acid-red': 'nucleic-acid-green'">
          {{measureItemInfo.nucleicAcidResult ===1?"阳性":"阴性"}}
        </span>
            </div>
            <div class="resp-rate">呼吸率: {{measureItemInfo.br}}</div>
            <div class="blood-pressure">血压: {{measureItemInfo.systolic}}/{{measureItemInfo.diastolic}}</div>
            <div class="blood-glucose">血糖: {{measureItemInfo.bloodGlucose}} mmol/L</div>
            <div class="spo2">血氧饱和度: {{measureItemInfo.spo2}} %</div>

          </div>

        </div>
      </div>
      <div class="paging-wrapper">
        <el-pagination
            background
            layout="prev, pager, next"
            :page-size="rows"
            :total="total" @current-change="handlePageChange">
        </el-pagination>
      </div>
    </div>



  </div>
</template>

<script>
import LocalStorage from "@/store/LocalStorage";
export default {
  name: "PersonInfoSummary", //个人信息汇总
  data() {
    return {
      title: "",
      uid: "",
      rows:10,
      total:0,
      page:1,
      pageCount: 0,
      measureInfoList:[],
      // measureItemInfo:{
      //   "id": "bc25dc9ba2a94155b5e5b3ca5f50826e",
      //   "userId": "2bc85295363b4f0c9462650505a2c368",
      //   "nucleicAcidResult": null,
      //   "antigenResult": 0,
      //   "nucleicAcidUrl": null,
      //   "antigenUrl": null,
      //   "bodyTemp": 39,
      //   "heartRate": 110,
      //   "systolic": 120,
      //   "diastolic": 85,
      //   "spo2": 93,
      //   "bloodGlucose": 8,
      //   "br": 60,
      //   "year": 2022,
      //   "month": 12,
      //   "day": 14,
      //   "createTime": "2022-12-14T08:53:08.390+0000",
      //   "updateTime": "2022-12-14T08:53:08.390+0000"
      // }
    }
  },
  created() {
    this.uid = LocalStorage.getUID()
    this.getMeasureHistoryList()
    this.title = this.$route.meta.title

  },
  methods: {
    getMeasureHistoryList() {
      this.$net.getMeasureHistory(this.page,this.rows,this.uid).then((res)=> {
        console.log(res.data)
        if (res.data.status === 200) {
          let data = res.data.data
          this.total = data.total;
          this.pageCount = data.pages
          this.measureInfoList = data.rows
        }else {
          console.log(res.data)
        }
      },(err)=> {
        console.log(err)
      })
    },
    handlePageChange(page) {
      this.page = page
      this.getMeasureHistoryList()
    },
    goSubmit() {
      this.$router.replace("/VitalSignsMonitoring")
    },
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.person-info-summary {
  display: flex;
  flex-direction: column;
  height: calc(100% - 46px);
  .content {
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    height: 100%;
    .measure-history-list {
      flex: 1;
      overflow-y: scroll;
      .measure-history-item {
        background-color: white;
        margin: 10px;
        display: flex;
        border-radius: 10px;
        flex-direction: column;
        align-items: center;
        padding: 6px;
        .item-title {

        }
        .item-content {
          display: flex;
          flex-wrap: wrap;
          div {
            width: 33%;
            font-size: 14px;
            margin-top: 8px;
          }
          .antigen-green { //抗原字体颜色
            color: #55BF3D;
          }
          .antigen-red {
            color: #ED1521;
          }
          .nucleic-acid-green {
            color: #55BF3D;
          }
          .nucleic-acid-red {
            color: #ED1521;
          }
          .blood-glucose,.spo2 {
            width: 50%;
          }

        }

      }
    }
    .empty-view {
      flex: 1;
    }

    .paging-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      padding:20px;


    }
  }

}
</style>
