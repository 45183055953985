<template>
  <div class="online-health-consulation">
    <div class="nav-bar">
      <van-nav-bar
          left-text="返回"
          :title="title"
          left-arrow
          @click-left="onClickLeft"
          fixed
          placeholder
      />
    </div>
    <div class="content">
      <div class="online-health-consultation-content">
        请您登陆浙里办，点击互联网医院进行在线健康咨询。或直接<a :href="zlbUrl">点击前往</a>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "OnlineHealthConsultation", //在线健康咨询
  data() {
    return {
      title: "",
      zlbUrl: this.$store.state.zlbDownloadUrl
    }
  },
  created() {
    this.title = this.$route.meta.title
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="scss">
.online-health-consulation {
  .content {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    padding: 20px;
    .online-health-consultation-content {
      margin-top: 200px;
    }
  }

}
</style>
